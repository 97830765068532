import { Card, CardContent, Typography, useMediaQuery } from "@mui/material";
import React from "react";
import { PieChart, Pie, Legend, Tooltip, Cell, Text } from "recharts";
import { categoricalColours } from "../../../components/Helper";

type PieChartSessionTypeVisProps = {
  size: { width: number; height: number; radius?: number };
  data: { name: string; value: number }[];
  visible?: boolean;
  typeColourAssignment?: { name: string; colour: string }[] | undefined;
};

// Pie chart component to visualise the session type (Gi/No Gi)
export const PieChartSessionTypeVis: React.VFC<PieChartSessionTypeVisProps> = ({
  size,
  data,
  visible = true,
  typeColourAssignment = undefined,
}: PieChartSessionTypeVisProps) => {
  const coloursForVis = categoricalColours(data.length);

  const matches = useMediaQuery("(max-width:414px)");

  return (
    <Card
      style={{
        width: `${size.width}px`,
        marginBottom: "1%",
        overflow: "visible",
        display: visible ? "initial" : "none",
      }}
    >
      <CardContent
        style={{
          paddingTop: matches ? "0px" : "16px",
          paddingBottom: matches ? "0px" : "24px",
        }}
      >
        <Typography color="textSecondary" gutterBottom>
          Type of Training
        </Typography>
        <PieChart width={size.width - 32} height={size.height}>
          <Text x={20} y={20}>
            Test
          </Text>
          <Legend verticalAlign="top" align="center" />
          <Pie
            dataKey="value"
            data={data}
            cx={(size.width - 32) / 2}
            cy={size.height / 2 - 10}
            outerRadius={size.radius}
          >
            {data.map((entry, index) => {
              return (
                <Cell
                  key={`cell-${index}`}
                  fill={
                    typeColourAssignment === undefined
                      ? coloursForVis[index]
                      : typeColourAssignment.find((element) => {
                          return element.name === entry.name;
                        })?.colour
                  }
                />
              );
            })}
          </Pie>
          <Tooltip
            allowEscapeViewBox={{ x: true, y: true }}
            formatter={(
              value: any,
              name: any,
              item: { payload?: { percentage: string } }
            ) => {
              return `${value} ${
                item.payload?.percentage
                  ? "(" + item.payload.percentage + ")"
                  : ""
              }`;
            }}
          />
        </PieChart>
      </CardContent>
    </Card>
  );
};
