import React from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEnvelopeSquare } from "@fortawesome/free-solid-svg-icons";
import { faGithub, faFreeCodeCamp } from "@fortawesome/free-brands-svg-icons";
import { Typography } from "@mui/material";

type CreatedByMessageProps = {};
export const CreatedByMessage: React.VFC<CreatedByMessageProps> = () => {
  return (
    <Typography variant="body1">
      Created by AbundantSalmon. <br />
      <FontAwesomeIcon icon={faGithub} />{" "}
      <a
        href="https://github.com/AbundantSalmon"
        target="_blank"
        rel="noreferrer"
        id="profile-link"
      >
        Github
      </a>
      <br />
      <FontAwesomeIcon icon={faEnvelopeSquare} />{" "}
      <a
        href="mailto:abundantsalmon@gmail.com"
        target="_blank"
        rel="noreferrer"
        id="profile-link"
      >
        abundantsalmon@gmail.com
      </a>
      <br />
      <FontAwesomeIcon icon={faFreeCodeCamp} />{" "}
      <a
        href="https://www.freecodecamp.org/abundantsalmon"
        target="_blank"
        rel="noreferrer"
        id="profile-link"
      >
        FreeCodeCamp
      </a>
    </Typography>
  );
};
