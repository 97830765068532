import { Typography, TextField, Button, Paper } from "@mui/material";
import { styled } from "@mui/material/styles";
import React, { useContext, useState } from "react";
import { AuthContext } from "../../App";
import "./LoginFormView.css";
import { LoginDetailsType } from "../../auth/useAuthentication";

const PREFIX = "LoginFormView";

const classes = {
  root: `${PREFIX}-root`,
};

const Root = styled("form")({
  [`& .${classes.root}`]: {
    margin: "5px",
  }, // a style rule
});

type LoginFormViewProps = {};

// view when the user is not currently logged in
// allows user to submit their login details
// displays demo user and password
export const LoginFormView: React.VFC<
  LoginFormViewProps
> = ({}: LoginFormViewProps) => {
  const authenticationState = useContext(AuthContext);

  const initialLoginDetails: LoginDetailsType = {
    username: "",
    password: "",
  };

  // login form state
  const [loginDetails, setLoginDetails] = useState(initialLoginDetails);

  // function to submit login details to backend
  const onSubmit = (event: React.FormEvent) => {
    authenticationState.login(loginDetails);
    event.preventDefault();
  };
  return (
    <Root autoComplete="off" onSubmit={onSubmit} id="loginForm">
      <Typography variant="h4">Login</Typography>
      <TextField
        className={classes.root}
        label="Username"
        name="username"
        size="small"
        variant="outlined"
        value={loginDetails.username}
        onChange={(e) =>
          setLoginDetails({ ...loginDetails, username: e.target.value })
        }
      />
      <TextField
        className={classes.root}
        label="Password"
        name="password"
        size="small"
        type="password"
        variant="outlined"
        value={loginDetails.password}
        onChange={(e) =>
          setLoginDetails({ ...loginDetails, password: e.target.value })
        }
      />
      <Button
        className={classes.root}
        color="primary"
        type="submit"
        variant="contained"
      >
        Log in
      </Button>

      <Paper variant="outlined" style={{ padding: "10px", marginTop: "10px" }}>
        <Typography variant="h6" style={{ marginTop: "0px" }}>
          Demo User Details
        </Typography>
        <Typography variant="body1">
          <b>Username:</b> demouser
          <br />
          <b>Password:</b> demopassword
        </Typography>
      </Paper>
    </Root>
  );
};
