import React from "react";
import { FormControl, MenuItem, Select } from "@mui/material";
import { SetMovesSkillAreaViewStateType } from "./SetMovesSkillArea";

type SetMovesSkillAreaUpdateTableProps = {
  setMovesSkillAreaViewState: {
    newMoveSkillAreas: SetMovesSkillAreaViewStateType;
  };
  setSetMovesSkillAreaViewState: React.Dispatch<
    React.SetStateAction<{
      newMoveSkillAreas: SetMovesSkillAreaViewStateType;
    }>
  >;
};

// table for fields for setting skill areas
export const SetMovesSkillAreaUpdateTable: React.VFC<
  SetMovesSkillAreaUpdateTableProps
> = ({
  setMovesSkillAreaViewState,
  setSetMovesSkillAreaViewState,
}: SetMovesSkillAreaUpdateTableProps) => {
  const rows = setMovesSkillAreaViewState.newMoveSkillAreas.map(
    (move, index) => {
      return (
        <tr key={move.id}>
          <td>{move.name}</td>
          <td>
            <FormControl fullWidth>
              <Select
                required
                variant="standard"
                value={move.skillarea}
                onChange={(event) => {
                  let newState = [
                    ...setMovesSkillAreaViewState.newMoveSkillAreas,
                  ];
                  newState[index].skillarea = event.target.value as string;
                  setSetMovesSkillAreaViewState({
                    ...setMovesSkillAreaViewState,
                    newMoveSkillAreas: newState,
                  });
                }}
              >
                <MenuItem value=""></MenuItem>
                <MenuItem value="Submission">Submission</MenuItem>
                <MenuItem value="Defence">Defence</MenuItem>
                <MenuItem value="Takedown">Takedown</MenuItem>
                <MenuItem value="Guard Pass">Guard Pass</MenuItem>
                <MenuItem value="Sweep">Sweep</MenuItem>
              </Select>
            </FormControl>
          </td>
        </tr>
      );
    }
  );
  return (
    <table style={{ textAlign: "left", marginTop: "10px" }}>
      <thead>
        <tr>
          <th>Move</th>
          <th>Skill Area</th>
        </tr>
      </thead>
      <tbody>{rows}</tbody>
    </table>
  );
};
