import { Typography } from "@mui/material";
import React, { useContext } from "react";
import { useNavigate } from "react-router-dom";
import { AuthContext } from "../../App";

type LoginSuccessMessageViewProps = {};

// View to display when login is successful
export const LoginSuccessMessageView: React.VFC<
  LoginSuccessMessageViewProps
> = ({}: LoginSuccessMessageViewProps) => {
  const navigate = useNavigate();
  const authenticationState = useContext(AuthContext);

  // redirect to logging after logging in
  setTimeout(() => {
    navigate("/logging");
  }, 2000);

  return (
    <div>
      <Typography variant="h4">Login Successful!</Typography>
      <Typography variant="h4">
        Welcome back {authenticationState.username}
      </Typography>
      <Typography variant="h5">Redirecting you shortly...</Typography>
    </div>
  );
};
