import React from "react";
import "./App.css";
import "@fontsource/roboto";
import { Route, Routes } from "react-router-dom";
import { Navigation } from "./components/Navigation/";
import { HomeView } from "./pages/HomeView/";
import { DataView } from "./pages/DataView/";
import { SubmissionDataView } from "./pages/SubmissionDataView/";
import { DataVisualisation } from "./pages/DataVisualisation/";
import { Logging } from "./pages/Logging/";
import { Login } from "./pages/Login/";
import { Logout } from "./pages/Logout/";
import { SubmissionTracking } from "./pages/SubmissionTracking/";
import { SetMovesSkillArea } from "./pages/UserProfile/SetMovesSkillArea/";
import { UserProfile } from "./pages/UserProfile/";
import { SetBeltInformation } from "./pages/UserProfile/SetBeltInformation/";
import {
  useAuthentication,
  UseAuthenticationReturnType,
} from "./auth/useAuthentication";
import { viewStates } from "./components/ViewStates";
import { SharedSummaryView } from "./pages/Shared/SharedSummaryView";

export const AuthContext = React.createContext<UseAuthenticationReturnType>({
  reloadAuth: () => {
    throw new Error("AuthContext not set");
  },
  username: "",
  login: async () => {
    throw new Error("AuthContext not set");
  },
  logout: async () => {
    throw new Error("AuthContext not set");
  },
  getToken: async () => null,
  isLoggedIn: false,
});

const App: React.VFC = () => {
  const authenticationContext = useAuthentication();

  return (
    <div className="App">
      <AuthContext.Provider value={authenticationContext}>
        <Navigation viewStates={viewStates}>
          <Routes>
            <Route path="/login" element={<Login />} />
            <Route path="/logout" element={<Logout />} />
            <Route path="/data-vis" element={<DataVisualisation />} />
            <Route path="/data-view" element={<DataView />} />
            <Route
              path="/submission-data-view"
              element={<SubmissionDataView />}
            />
            <Route path="/logging" element={<Logging />} />
            <Route
              path="/submission-tracking"
              element={<SubmissionTracking />}
            />
            <Route path="/set-skill-area" element={<SetMovesSkillArea />} />
            <Route
              path="/set-belt-information"
              element={<SetBeltInformation />}
            />
            <Route
              path="/user-profile"
              element={<UserProfile viewStates={viewStates} />}
            />
            <Route
              path="/shared/:username"
              key="shared"
              element={<SharedSummaryView />}
            />
            <Route path="/" element={<HomeView />} />
          </Routes>
        </Navigation>
      </AuthContext.Provider>
    </div>
  );
};

export default App;
