import React from "react";
import { formatDateString } from "utils/formatDateString";
import "./BeltTable.css";

const BELT_LEVELS = ["white", "blue", "purple", "brown", "black"] as const;
const STRIPE_LEVELS = ["0", "1", "2", "3", "4"] as const;
type BeltLevelType = typeof BELT_LEVELS[number];
type StripeLevelType = typeof STRIPE_LEVELS[number];
type StripeSummaryType = {
  matHrs: number;
  date: string | null;
  months: number;
};
type BeltSummaryType = {
  [key in StripeLevelType]: StripeSummaryType;
} & {
  beltMatTime: number;
  beltYears: number;
};
export type TotalBeltSummaryType = {
  [key in BeltLevelType]: BeltSummaryType;
};

type SharedBeltTableProps = {
  data: TotalBeltSummaryType;
};

export const SharedBeltTableTable: React.VFC<SharedBeltTableProps> = ({
  data,
}: SharedBeltTableProps) => {
  // Generate table divs
  const beltDivs = BELT_LEVELS.map((colour) => {
    // Generate divs with the stripe information
    const stripeArray = STRIPE_LEVELS.map((number) => {
      const stripeMatTime = data[colour][number].matHrs;
      const stripeDate = data[colour][number].date;
      const stripeMonths = data[colour][number].months;
      return (
        <React.Fragment key={`${colour}${number}`}>
          <div className={`${colour} startBelt`}></div>
          <div className={`${colour} stripeDiv stripe${number}`}>
            <div className="stripe"></div>
            <div className="stripe"></div>
            <div className="stripe"></div>
            <div className="stripe"></div>
          </div>
          <div className={`${colour} afterStripes`}></div>
          <div className={`${colour} beltText`}>
            {stripeDate === null ? null : stripeMatTime.toFixed(2)}
          </div>
          <div className={`${colour} beltText`}>
            {stripeDate === null ? null : formatDateString(stripeDate)}
          </div>
          <div className={`${colour} beltText endBelt`}>
            {stripeDate === null ? null : stripeMonths.toFixed(2)}
          </div>
        </React.Fragment>
      );
    });

    // Add in totalBeltMatTime and totalBeltYears divs
    const beltMatTime = data[colour].beltMatTime;
    const beltYears = data[colour].beltYears;
    return (
      <React.Fragment key={colour}>
        {stripeArray}
        <div className={`${colour} totalBeltMatTime totalBeltMatTimeContent`}>
          {beltMatTime === 0 ? null : beltMatTime.toFixed(1)}
        </div>
        <div className={`${colour} totalBeltYears totalBeltYearsContent`}>
          {beltYears === 0 ? null : beltYears.toFixed(2)}
        </div>
      </React.Fragment>
    );
  });

  return (
    <div className="beltTable">
      <div></div>
      <div></div>
      <div></div>
      <div className="beltText">Mat Time (hr)</div>
      <div className="beltText">Date</div>
      <div className="beltText">Months</div>
      <div className="beltText totalBeltMatTime">Mat Time (hr)</div>
      <div className="beltText totalBeltYears">Years</div>
      {beltDivs}
    </div>
  );
};
