import React from "react";
import {
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  Card,
  CardContent,
  IconButton,
  Container,
  Paper,
  Typography,
  Button,
  Autocomplete,
} from "@mui/material";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import { DatePicker, LocalizationProvider } from "@mui/x-date-pickers";
import { TextField } from "@mui/material";
import { useSnackbar } from "notistack";
import {
  FormValuesType,
  SanitisedFormDataType,
  SubmissionTrackingViewState,
} from "./SubmissionTracking";
import RemoveCircleRoundedIcon from "@mui/icons-material/RemoveCircleRounded";
import AddCircleRoundedIcon from "@mui/icons-material/AddCircleRounded";
import { dateToISOLikeButLocal } from "../../components/Helper";
import "./SubmissionTrackingLoaded.css";
import { Move } from "data/queries/useMoves";

// MUIDataTable in SubmissionDataView
// view after the data has been loaded
type SubmissionTrackingLoadedProps = {
  appState: {
    moves: Move[];
  };
  setAppState: React.Dispatch<
    React.SetStateAction<SubmissionTrackingViewState>
  >;
  apiInsertSubmissionCall: (sanitisedFormData: SanitisedFormDataType) => void;
  formValues: FormValuesType;
  setFormValues: React.Dispatch<React.SetStateAction<FormValuesType>>;
  initialFormValues: FormValuesType;
  isSubmitting: boolean;
};
export const SubmissionTrackingLoaded: React.VFC<
  SubmissionTrackingLoadedProps
> = ({
  appState,
  setAppState,
  apiInsertSubmissionCall,
  formValues,
  setFormValues,
  initialFormValues,
  isSubmitting,
}: SubmissionTrackingLoadedProps) => {
  const { enqueueSnackbar } = useSnackbar();

  const onSubmit = (event: React.FormEvent) => {
    event.preventDefault();
    let error = false;
    const sanitisedFormData = formValues.entries.map((entry) => {
      if (entry.submission === "" || entry.submission === null) {
        enqueueSnackbar("Error, submission(s) must not be blank!", {
          variant: "error",
        });
        error = true;
      }
      return {
        ...entry,
        date: dateToISOLikeButLocal(entry.date).split("T", 1)[0],
        submission: entry.submission === null ? "" : entry.submission!.trim(),
      };
    });
    if (error) {
      // do not do api call if there are blank submissions
      return;
    }
    apiInsertSubmissionCall(sanitisedFormData);
  };

  // function to generate a form for a single entry
  const formInputRow = (entryIndex: number) => {
    return (
      <>
        <DatePicker
          inputFormat="dd/MM/yyyy"
          label="Date of Submission"
          value={formValues.entries[entryIndex].date}
          renderInput={(params) => (
            <TextField
              id={`date-of-entry${entryIndex}`}
              variant="standard"
              required
              {...params}
            />
          )}
          onChange={(date) => {
            let newEntries = [...formValues.entries];
            newEntries[entryIndex].date = date!;
            setFormValues({
              ...formValues,
              entries: newEntries,
            });
          }}
        />
        <Autocomplete
          autoSelect
          freeSolo
          onChange={(e, newval, reason) => {
            let newEntries = [...formValues.entries];
            newEntries[entryIndex].submission = newval!;
            setFormValues({
              ...formValues,
              entries: newEntries,
            });
          }}
          value={formValues.entries[entryIndex].submission}
          options={appState.moves
            .filter((value) => value.skillarea === "Submission")
            .map((value) => value.name)}
          getOptionLabel={(move) => move}
          renderInput={(params) => (
            <TextField
              {...params}
              variant="standard"
              label="Submission"
              placeholder="Submission"
              required
            />
          )}
        />
        <TextField
          label="Number of Submissions"
          type="number"
          variant="standard"
          InputProps={{
            inputProps: {
              min: "1",
              step: "1",
              style: { textAlign: "center" },
            },
          }}
          InputLabelProps={{
            shrink: true,
          }}
          value={formValues.entries[entryIndex].number}
          onChange={(event) => {
            let newEntries = [...formValues.entries];
            newEntries[entryIndex].number = parseInt(event.target.value);
            setFormValues({
              ...formValues,
              entries: newEntries,
            });
          }}
        />
        <FormControl variant="standard">
          <InputLabel shrink id="levelOfSubmitted">
            Level of the Submitted
          </InputLabel>
          <Select
            required
            value={formValues.entries[entryIndex].opponentLevel}
            onChange={(event) => {
              let newEntries = [...formValues.entries];
              newEntries[entryIndex].opponentLevel = event.target
                .value as string;
              setFormValues({
                ...formValues,
                entries: newEntries,
              });
            }}
          >
            <MenuItem value="White">White</MenuItem>
            <MenuItem value="Blue">Blue</MenuItem>
            <MenuItem value="Purple">Purple</MenuItem>
            <MenuItem value="Brown">Brown</MenuItem>
            <MenuItem value="Black">Black</MenuItem>
          </Select>
        </FormControl>
      </>
    );
  };

  // generates the forms dependant on the state of the required entries
  const formInputRows = formValues.entries.map((row, index) => {
    return (
      <div key={index} style={{ display: "flex", flexDirection: "row" }}>
        <div
          style={{
            width: "48px",
            display: "flex",
            flexDirection: "column",
            justifyContent: "flex-end",
          }}
        ></div>
        <Card
          variant="outlined"
          style={{ marginTop: "10px", marginBottom: "10px", flexGrow: 1 }}
        >
          <CardContent>
            <div style={{ display: "flex", flexDirection: "column" }}>
              {formInputRow(index)}
            </div>
          </CardContent>
        </Card>
        <div
          style={{
            width: "48px",
            display: "flex",
            flexDirection: "column",
            justifyContent: "flex-end",
          }}
        >
          {formValues.entries.length !== 1 ? (
            <IconButton
              color="secondary"
              aria-label="subtract"
              onClick={() => {
                const newEntries = formValues.entries.filter(
                  (element, entryIndex) => {
                    return entryIndex !== index;
                  }
                );
                setFormValues({
                  ...formValues,
                  entries: newEntries,
                });
              }}
              size="large"
            >
              <RemoveCircleRoundedIcon />
            </IconButton>
          ) : (
            <></>
          )}
          {index === formValues.entries.length - 1 ? (
            <IconButton
              color="primary"
              aria-label="add"
              onClick={() => {
                let newEntries = [...formValues.entries];
                newEntries.push({ ...initialFormValues.entries[0] });
                setFormValues({
                  ...formValues,
                  entries: newEntries,
                });
              }}
              size="large"
            >
              <AddCircleRoundedIcon />
            </IconButton>
          ) : (
            <></>
          )}
        </div>
      </div>
    );
  });
  return (
    <>
      <Container maxWidth="md" id="submissionTrackingView">
        <Paper elevation={3}>
          <LocalizationProvider dateAdapter={AdapterDateFns}>
            <form
              autoComplete="off"
              onSubmit={onSubmit}
              onKeyPress={(e) => {
                e.key === "Enter" && e.preventDefault();
              }}
              id="submissionTrackingForm"
            >
              <Typography variant="h4">Track Submissions</Typography>
              {formInputRows}
              <div id="submissionTrackingButtonDiv">
                <Button
                  color="primary"
                  type="submit"
                  variant="contained"
                  disabled={isSubmitting}
                >
                  Log
                </Button>
              </div>
            </form>
          </LocalizationProvider>
        </Paper>
        <br />
        <Button
          color="primary"
          variant="contained"
          onClick={() => setAppState({ ...appState, state: "import" })}
        >
          Import Data from CSV
        </Button>
      </Container>
    </>
  );
};
