import { MenuItem } from "@mui/material";
import React from "react";
import { Link, LinkProps } from "react-router-dom";

type MenuItemLinkProps = {
  text: string;
  to: string;
  onClick: (event: React.MouseEvent<EventTarget>) => void;
};

// component used as MenuItem that also is a react-router route link
export const MenuItemLink: React.FC<MenuItemLinkProps> = ({
  text,
  to,
  onClick,
}: MenuItemLinkProps) => {
  const CustomLink = React.useMemo(
    () =>
      React.forwardRef<any, Omit<LinkProps, "to">>(function CustomLink(
        props,
        ref
      ) {
        return <Link ref={ref} to={to} {...props} />;
      }),
    [to]
  );

  return (
    <MenuItem onClick={onClick} component={CustomLink}>
      {text}
    </MenuItem>
  );
};
