import React from "react";
import { styled } from "@mui/material/styles";
import { ListItemLink } from "./ListItemLink";
import { RightCornerComponent } from "./RightCornerComponent";
import clsx from "clsx";
import "./Navigation.css";
import AppBar from "@mui/material/AppBar";
import Toolbar from "@mui/material/Toolbar";
import Typography from "@mui/material/Typography";
import IconButton from "@mui/material/IconButton";
import MenuIcon from "@mui/icons-material/Menu";
import ChevronLeftIcon from "@mui/icons-material/ChevronLeft";
import ChevronRightIcon from "@mui/icons-material/ChevronRight";
import { CssBaseline, Divider, Drawer, List, useTheme } from "@mui/material";
import { matchPath, useLocation } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEnvelopeSquare } from "@fortawesome/free-solid-svg-icons";
import { faGithub } from "@fortawesome/free-brands-svg-icons";

const PREFIX = "Navigation";

const classes = {
  root: `${PREFIX}-root`,
  appBar: `${PREFIX}-appBar`,
  appBarShift: `${PREFIX}-appBarShift`,
  menuButton: `${PREFIX}-menuButton`,
  hide: `${PREFIX}-hide`,
  drawer: `${PREFIX}-drawer`,
  drawerPaper: `${PREFIX}-drawerPaper`,
  drawerHeader: `${PREFIX}-drawerHeader`,
  content: `${PREFIX}-content`,
  contentShift: `${PREFIX}-contentShift`,
  title: `${PREFIX}-title`,
};

const Root = styled("div")(({ theme }) => ({
  [`&.${classes.root}`]: {
    display: "flex",
  },
  [`& .${classes.appBar}`]: {
    transition: theme.transitions.create(["margin", "width"], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
  },
  [`& .${classes.appBarShift}`]: {
    width: `calc(100% - ${drawerWidth}px)`,
    marginLeft: drawerWidth,
    transition: theme.transitions.create(["margin", "width"], {
      easing: theme.transitions.easing.easeOut,
      duration: theme.transitions.duration.enteringScreen,
    }),
  },
  [`& .${classes.menuButton}`]: {
    marginRight: theme.spacing(2),
  },
  [`& .${classes.hide}`]: {
    display: "none",
  },
  [`& .${classes.drawer}`]: {
    width: drawerWidth,
    flexShrink: 0,
  },
  [`& .${classes.drawerPaper}`]: {
    width: drawerWidth,
  },
  [`& .${classes.drawerHeader}`]: {
    display: "flex",
    alignItems: "center",
    padding: theme.spacing(0, 1),
    // necessary for content to be below app bar
    ...theme.mixins.toolbar,
    justifyContent: "flex-end",
  },
  [`& .${classes.content}`]: {
    flexGrow: 1,
    padding: theme.spacing(3),
    paddingTop: 0,
    transition: theme.transitions.create("margin", {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    marginLeft: -drawerWidth,
    ["@media (max-width:1024px)"]: {
      padding: theme.spacing(0),
    },
  },
  [`&.${classes.title}`]: {
    flexGrow: 1,
  },
  [`& .${classes.contentShift}`]: {
    transition: theme.transitions.create("margin", {
      easing: theme.transitions.easing.easeOut,
      duration: theme.transitions.duration.enteringScreen,
    }),
    marginLeft: 0,
  },
}));

const drawerWidth = 240;

type NavigationProps = {
  viewStates: ViewState[];
  children: React.ReactNode;
};

// component is a navigation wrapper around the other views
// inspired by https://material-ui.com/components/drawers/#persistent-drawer
// in future potentially change and implement https://material-ui.com/components/drawers/#swipeable
export const Navigation: React.VFC<NavigationProps> = ({
  children,
  viewStates,
}: NavigationProps) => {
  const theme = useTheme();
  const location = useLocation();

  // left hand navigation drawer state
  const [open, setOpen] = React.useState(false);

  const handleDrawerOpen = () => {
    setOpen(true);
  };

  const handleDrawerClose = () => {
    setOpen(false);
  };

  const menuItems = () => {
    const menuItemComponents = viewStates
      .filter((value) => value.inSideMenu)
      .map((value) => {
        return (
          <ListItemLink
            to={value.routeLocation}
            text={value.displayName}
            key={value.key}
            icon={value.icon ? value.icon : undefined}
            requiresLogin={value.requiresLogin}
          />
        );
      });
    return menuItemComponents;
  };

  return (
    <Root className={classes.root}>
      <CssBaseline />
      <AppBar
        position="fixed"
        className={clsx(classes.appBar, {
          [classes.appBarShift]: open,
        })}
      >
        <Toolbar
          variant="dense"
          style={{
            display: "flex",
            flexDirection: "row",
            justifyContent: "space-between",
          }}
        >
          <div
            style={{ flex: 1, justifyContent: "flex-start", display: "flex" }}
          >
            <IconButton
              color="inherit"
              aria-label="open drawer"
              onClick={handleDrawerOpen}
              edge="start"
              className={clsx(classes.menuButton, open && classes.hide)}
              size="large"
            >
              <MenuIcon />
            </IconButton>
          </div>
          <Typography
            display="inline"
            variant="h6"
            noWrap
            className={classes.title}
            style={{ flex: 1 }}
          >
            {`${
              viewStates.find((view) =>
                matchPath(view.routeLocation, location.pathname)
              )?.displayName
            } - Grapple-Training-Log`}
          </Typography>
          <div style={{ flex: 1, justifyContent: "flex-end", display: "flex" }}>
            <RightCornerComponent />
          </div>
        </Toolbar>
      </AppBar>
      <Drawer
        className={classes.drawer}
        variant="persistent"
        anchor="left"
        open={open}
        classes={{
          paper: classes.drawerPaper,
        }}
      >
        <div className={classes.drawerHeader}>
          <IconButton onClick={handleDrawerClose} size="large">
            {theme.direction === "ltr" ? (
              <ChevronLeftIcon />
            ) : (
              <ChevronRightIcon />
            )}
          </IconButton>
        </div>
        <Divider />
        <List>{menuItems()}</List>
        <Divider />
      </Drawer>
      <main
        className={clsx(classes.content, {
          [classes.contentShift]: open,
        })}
        style={{
          minHeight: "calc(100vh - 5px)",
          display: "flex",
          flexDirection: "column",
          paddingBottom: 0,
        }}
      >
        <div className={classes.drawerHeader} />
        {children}
        <div style={{ flex: 1 }} />
        <div
          style={{
            width: "100%",
            textAlign: "center",
            marginTop: "5px",
            paddingBottom: "5px",
          }}
        >
          Created by AbundantSalmon{" "}
          <a
            href="https://github.com/AbundantSalmon"
            target="_blank"
            rel="noreferrer"
            id="profile-link"
            style={{ color: "#000000", textDecoration: "none" }}
          >
            <FontAwesomeIcon icon={faGithub} />
          </a>
          <a
            href="mailto:abundantsalmon@gmail.com"
            target="_blank"
            rel="noreferrer"
            id="profile-link"
            style={{ color: "#000000", textDecoration: "none" }}
          >
            <FontAwesomeIcon icon={faEnvelopeSquare} />
          </a>
        </div>
      </main>
    </Root>
  );
};
