import { AuthContext } from "App";
import axios from "axios";
import { useContext } from "react";
import { useQuery } from "react-query";
import { Move } from "./useMoves";
import { Position } from "./usePositions";

export type Session = {
  date: string;
  duration: number;
  duration_rolling_rounds: number;
  duration_situational_rounds: number;
  id: number;
  injury_notes: string;
  mode: string;
  num_rolling_rounds: number;
  num_situational_rounds: number;
  session: number;
  situational_position: number;
  training_notes: string;
  type: string;
  userid: number;
  moves: Move[];
  positions: Position[];
};

const getSessions = async (
  getToken: () => Promise<string | null>,
  signal?: AbortSignal
) => {
  const tokenValue = await getToken();

  return (
    await axios.request<Session[]>({
      url: "/api/sessions",
      method: "GET",
      headers: {
        Authorization: `Bearer ${tokenValue}`,
      },
      signal,
    })
  ).data;
};

const useSessions = () => {
  const authenticationState = useContext(AuthContext);
  return useQuery(["sessions"], ({ signal }) =>
    getSessions(authenticationState.getToken, signal)
  );
};

export default useSessions;
