import React, { useState } from "react";
import Loading from "components/Loading";
import ErrorComponent from "components/ErrorComponent";
import useInsertData from "../../data/mutations/useInsertData";
import { SubmissionTrackingLoaded } from "./SubmissionTrackingLoaded";
import { SubmissionTrackingImportView } from "./SubmissionTrackingImportView";
import { sortHelper } from "../../components/Helper";
import useMoves from "data/queries/useMoves";

export type FormValuesType = {
  entries: {
    date: Date;
    submission: string | null;
    number: number;
    opponentLevel: string;
  }[];
};

export type SanitisedFormDataType = {
  date: string;
  submission: string;
  number: number;
  opponentLevel: string;
}[];

export type SubmissionTrackingViewState = { state: "loaded" | "import" };

type SubmissionTrackingProps = {};

// user uses this form to track their submissions obtained in rolling
// entries can be logged on at a time or multiple entries can be added
export const SubmissionTracking: React.VFC<SubmissionTrackingProps> = () => {
  const [viewState, setViewState] = useState<SubmissionTrackingViewState>({
    state: "loaded",
  });
  const moves = useMoves();
  const initialFormValues: FormValuesType = {
    entries: [
      {
        date: new Date(),
        submission: null,
        number: 1,
        opponentLevel: "White",
      },
    ],
  };

  const [formValues, setFormValues] = useState(initialFormValues);

  const { apiCall, status } = useInsertData<SanitisedFormDataType>(
    "api/submission-trackings/bulk-create",
    {
      method: "POST",
      onSuccessCallback(data, variables, context) {
        setFormValues(initialFormValues);
        moves.refetch();
      },
    }
  );

  if (moves.isLoading || moves.isIdle) {
    return <Loading />;
  }
  if (moves.isError) {
    return <ErrorComponent />;
  }

  const appState = {
    moves: moves.data.sort(sortHelper),
  };

  return (
    <>
      {viewState.state === "loaded" ? (
        <SubmissionTrackingLoaded
          appState={appState}
          setAppState={setViewState}
          apiInsertSubmissionCall={apiCall}
          isSubmitting={status === "loading"}
          formValues={formValues}
          setFormValues={setFormValues}
          initialFormValues={initialFormValues}
        />
      ) : viewState.state === "import" ? (
        <SubmissionTrackingImportView
          appState={appState}
          setAppState={setViewState}
          apiInsertSubmissionCall={apiCall}
          isSubmitting={status === "loading"}
        />
      ) : (
        <Loading />
      )}
    </>
  );
};
