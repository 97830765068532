import { Card, CardContent, Typography, useMediaQuery } from "@mui/material";
import React from "react";
import {
  Bar,
  BarChart,
  Brush,
  CartesianGrid,
  Label,
  Tooltip,
  XAxis,
  YAxis,
} from "recharts";

type BarChartWeeklySessionsVisProps = {
  size: { width: number; height: number };
  label?: { xAxisLabel: string; yAxisLabel: string };
  data: { name: string; value: number }[];
};

// Bar chart component to display weekly session hours
export const BarChartWeeklySessionsVis: React.VFC<
  BarChartWeeklySessionsVisProps
> = ({ size, label, data }: BarChartWeeklySessionsVisProps) => {
  const matches = useMediaQuery("(max-width:414px)");
  return (
    <Card
      style={{
        width: `${size.width}px`,
        marginBottom: "1%",
        paddingRight: matches ? "0px" : "16px",
        overflow: "visible",
      }}
    >
      <CardContent
        style={{
          paddingRight: matches ? "0px" : "16px",
        }}
      >
        <Typography color="textSecondary" gutterBottom>
          Weekly Mat Hours
        </Typography>
        <BarChart
          width={matches ? size.width - 16 : size.width - 32}
          height={size.height + 28}
          data={data}
          margin={{
            top: 0,
            right: 70,
            left: 0,
            bottom: 0,
          }}
        >
          <CartesianGrid strokeDasharray="3 3" />
          <XAxis dataKey="name" hide={true} />
          <YAxis>
            <Label
              angle={-90}
              value={label === undefined ? undefined : label.yAxisLabel}
              position="insideLeft"
              style={{ textAnchor: "middle" }}
            />
          </YAxis>
          <Tooltip
            labelFormatter={(name) => {
              return `Week: ${name}`;
            }}
            formatter={(value: number, name: string, props: any) => {
              return [value, `${label?.yAxisLabel}`];
            }}
          />
          <Bar dataKey="value" fill="#8884d8" />
          <Brush
            height={28}
            data={data}
            tickFormatter={(index) => {
              return data[index].name;
            }}
          />
        </BarChart>
      </CardContent>
    </Card>
  );
};
