import { Button, Paper, Typography, useMediaQuery } from "@mui/material";
import { compareAsc, parseISO, addMonths } from "date-fns";
import { DateRangePicker } from "rsuite";
import React, { useState } from "react";
import ReactTooltip from "react-tooltip";
import { sortDateHelper } from "../../components/Helper";
import { BarChartMonthlySessionsVis } from "./charts/BarChartMonthlySessionsVis";
import { BarChartWeeklySessionsVis } from "./charts/BarChartWeeklySessionsVis";
import { LineChartCumulativeHoursVis } from "./charts/LineChartCumulativeHoursVis";
import { PieChartMovesFocusVis } from "./charts/PieChartMovesFocusVis";
import { PieChartMovesSkillAreaFocusVis } from "./charts/PieChartMovesSkillAreaFocusVis";
import { PieChartPositionsFocusVis } from "./charts/PieChartPositionsFocusVis";
import { PieChartSessionTypeVis } from "./charts/PieChartSessionTypeVis";
import { PieChartSubmissionTrackingVis } from "./charts/PieChartSubmissionTrackingVis";
import { CompareChartDialog } from "./CompareChartDialog";
import {
  cumulativeMatHoursGenerator,
  dailyCalendarHeatmapsGenerator,
  DataVisualisationAppData,
  monthlyAttendanceDataGenerator,
  movesFocusInSessionsDataGenerator,
  movesSkillAreaFocusDataGenerator,
  positionsFocusInSessionsDataGenerator,
  sessionTypePieDataGenerator,
  submissionTrackingDataGenerator,
  weeklyAttendanceDataGenerator,
} from "./DataVisualisation";
import "./DataVisualisationLoaded.css";
import "rsuite/dist/rsuite.min.css";

type DataVisualisationLoadedProps = {
  appState: DataVisualisationAppData;
};

interface DateRange {
  startDate: Date;
  endDate: Date;
}

// View when data has been loaded
export const DataVisualisationLoaded: React.VFC<
  DataVisualisationLoadedProps
> = ({ appState }: DataVisualisationLoadedProps) => {
  const matches = useMediaQuery("(max-width:414px)");

  // earliest date of session data
  const minDate = appState.sessions.sort(sortDateHelper)[0].date;

  // latest date of session data
  const maxDate =
    appState.sessions.sort(sortDateHelper)[appState.sessions.length - 1].date;

  // date range filter state
  const [selectionRange, setSelectionRange] = useState<DateRange>({
    startDate: new Date(minDate),
    endDate: new Date(maxDate),
  });

  // State of the date range picker
  const [open, setOpen] = useState(false);
  const toggle = () => setOpen(!open);

  // Retrieve data from database is filtered by date for charting
  // based on selectionRange state
  const filteredAppState = {
    ...appState,
    sessions: appState.sessions.filter((element) => {
      if (
        compareAsc(parseISO(element.date), selectionRange.startDate!) >= 0 &&
        compareAsc(parseISO(element.date), selectionRange.endDate!) <= 0
      ) {
        return true;
      }
      return false;
    }),
    submissionTracking: appState.submissionTracking.filter((element) => {
      if (
        compareAsc(parseISO(element.date), selectionRange.startDate!) >= 0 &&
        compareAsc(parseISO(element.date), selectionRange.endDate!) <= 0
      ) {
        return true;
      }
      return false;
    }),
  };

  // Data for charts
  const sessionTypePieData = sessionTypePieDataGenerator(filteredAppState);
  const cumulativeMatHoursData = cumulativeMatHoursGenerator(filteredAppState);
  const monthlyAttendanceData =
    monthlyAttendanceDataGenerator(filteredAppState);
  const dailyCalendarHeatmaps =
    dailyCalendarHeatmapsGenerator(filteredAppState);
  const weeklyAttendanceData = weeklyAttendanceDataGenerator(filteredAppState);
  const positionsFocusInSessionsData =
    positionsFocusInSessionsDataGenerator(filteredAppState);
  const movesFocusInSessionsData =
    movesFocusInSessionsDataGenerator(filteredAppState);
  const movesSkillAreaFocusData =
    movesSkillAreaFocusDataGenerator(filteredAppState);
  const submissionTrackingData =
    submissionTrackingDataGenerator(filteredAppState);

  // Chart sizing
  const cardHeight = 300; //210
  const pieRadius = 80;

  // State of the modal dialog
  const [openDialog, setOpenDialog] = React.useState(false);

  const handleClickOpen = () => {
    setOpenDialog(true);
  };

  const handleClose = () => {
    setOpenDialog(false);
  };

  return (
    <>
      <Paper elevation={3} style={{ paddingTop: "10px" }}>
        <Typography variant="h4">Dashboard</Typography>
        <DateRangePicker
          onChange={(range) => {
            if (range) {
              setSelectionRange({
                startDate: range[0],
                endDate: range[1],
              });
            }
          }}
          ranges={[
            {
              label: "All-Time",
              value: [new Date(minDate), new Date()],
              placement: "left",
            },
            {
              label: "Last 3 Months",
              value: [addMonths(new Date(), -3), new Date()],
              placement: "left",
            },
            {
              label: "Last 6 Months",
              value: [addMonths(new Date(), -6), new Date()],
              placement: "left",
            },
            {
              label: "Last 12 Months",
              value: [addMonths(new Date(), -12), new Date()],
              placement: "left",
            },
          ]}
        />

        <div
          style={{
            display: "flex",
            flexDirection: "row",
            justifyContent: "center",
            marginTop: "5px",
            marginBottom: "5px",
          }}
        >
          <Button variant="contained" color="primary" onClick={handleClickOpen}>
            Compare
          </Button>
        </div>

        <div
          style={{
            display: "flex",
            flexWrap: "wrap",
            justifyContent: "space-around",
            alignContent: "space-between",
            backgroundColor: "#f5f5f5",
            padding: matches ? "0px" : "10px",
            paddingTop: "15px",
            margin: matches ? "0px" : "10px",
            borderRadius: "5px",
          }}
        >
          <LineChartCumulativeHoursVis
            size={{ width: matches ? 375 : 500, height: cardHeight }}
            data={cumulativeMatHoursData}
            label={{ xAxisLabel: "Date", yAxisLabel: "Cumulative Hours" }}
          />
          <BarChartWeeklySessionsVis
            size={{ width: matches ? 375 : 500, height: cardHeight }}
            data={weeklyAttendanceData}
            label={{ xAxisLabel: "", yAxisLabel: "Hours" }}
          />
          <BarChartMonthlySessionsVis
            size={{ width: matches ? 375 : 500, height: cardHeight }}
            data={monthlyAttendanceData}
            label={{ xAxisLabel: "Month", yAxisLabel: "Hours" }}
          />
          <PieChartSessionTypeVis
            size={{
              width: matches ? 375 : 500,
              height: cardHeight,
              radius: pieRadius,
            }}
            data={sessionTypePieData}
          />
          <PieChartMovesSkillAreaFocusVis
            size={{
              width: matches ? 375 : 500,
              height: cardHeight,
              radius: pieRadius,
            }}
            data={movesSkillAreaFocusData}
          />
          <PieChartSubmissionTrackingVis
            size={{
              width: matches ? 375 : 500,
              height: cardHeight,
              radius: pieRadius,
            }}
            data={submissionTrackingData}
          />
          <PieChartPositionsFocusVis
            size={{
              width: matches ? 375 : 500,
              height: cardHeight,
              radius: pieRadius,
            }}
            data={positionsFocusInSessionsData}
          />
          <PieChartMovesFocusVis
            size={{
              width: matches ? 375 : 500,
              height: cardHeight,
              radius: pieRadius,
            }}
            data={movesFocusInSessionsData}
          />
        </div>
        <Typography variant="h5">Training Calendar</Typography>
        <div id="dailyCalendarHeatMapsGrid">{dailyCalendarHeatmaps}</div>
        <ReactTooltip html={true} />
        <CompareChartDialog
          appState={appState}
          minDate={minDate}
          maxDate={maxDate}
          openDialog={openDialog}
          handleClose={handleClose}
        />
      </Paper>
    </>
  );
};
