import {
  Button,
  Dialog,
  FormControl,
  MenuItem,
  Select,
  useMediaQuery,
} from "@mui/material";
import { compareAsc, parseISO, addMonths } from "date-fns";
import React, { useState } from "react";
import { categoricalColours } from "../../components/Helper";
import { PieChartMovesFocusVis } from "./charts/PieChartMovesFocusVis";
import { PieChartMovesSkillAreaFocusVis } from "./charts/PieChartMovesSkillAreaFocusVis";
import { PieChartPositionsFocusVis } from "./charts/PieChartPositionsFocusVis";
import { PieChartSessionTypeVis } from "./charts/PieChartSessionTypeVis";
import { PieChartSubmissionTrackingVis } from "./charts/PieChartSubmissionTrackingVis";
import {
  DataVisualisationAppData,
  movesFocusInSessionsDataGenerator,
  movesSkillAreaFocusDataGenerator,
  positionsFocusInSessionsDataGenerator,
  sessionTypePieDataGenerator,
  submissionTrackingDataGenerator,
} from "./DataVisualisation";

type CompareChartDialogProps = {
  appState: DataVisualisationAppData;
  minDate: string;
  maxDate: string;
  openDialog: boolean;
  handleClose: () => void;
};

// modal dialog used to compare the same chart but over different predefined ranges
export const CompareChartDialog: React.VFC<CompareChartDialogProps> = ({
  appState,
  minDate,
  maxDate,
  openDialog,
  handleClose,
}: CompareChartDialogProps) => {
  // State of which chart is visible in the modal dialog
  const [visibleChart, setVisibleChart] = React.useState(0);

  const matches = useMediaQuery("(max-width:414px)");
  const ipadMatches = useMediaQuery("(max-width:768px)");

  // Chart sizing
  const cardHeight = 300; //210
  const pieRadius = 80;

  // colours for piechart
  const coloursForType = categoricalColours(2);
  const coloursForSkills = categoricalColours(5);
  const coloursForMoves = categoricalColours(appState.moves.length);
  const coloursForPositions = categoricalColours(appState.positions.length);

  // assign colours to specific labels before filtering
  // This allows for consistent colours between charts in the comparison dialog
  const typeColourAssignment = ["Gi", "No Gi"].map((element, index) => {
    return { name: element, colour: coloursForType[index] };
  });
  const skillsColourAssignment = [
    "Submission",
    "Guard Pass",
    "Defence",
    "Sweep",
    "Takedown",
  ].map((element, index) => {
    return { name: element, colour: coloursForSkills[index] };
  });
  const movesColourAssignment = appState.moves.map((element, index) => {
    return {
      name: element.name,
      colour: coloursForMoves[index % coloursForMoves.length],
    };
  });
  const positionsColourAssignment = appState.positions.map((element, index) => {
    return {
      name: element.name,
      colour: coloursForPositions[index % coloursForPositions.length],
    };
  });

  // state of left and right date range filters
  const [dialogRange, setDialogRange] = useState({
    left: { startDate: new Date(minDate), endDate: new Date(maxDate) },
    right: { startDate: new Date(minDate), endDate: new Date(maxDate) },
  });

  // filtered state for the left chart in modal dialog
  const leftFilteredAppState = {
    ...appState,
    sessions: appState.sessions.filter((element) => {
      if (
        compareAsc(parseISO(element.date), dialogRange.left.startDate!) >= 0 &&
        compareAsc(parseISO(element.date), dialogRange.left.endDate!) <= 0
      ) {
        return true;
      }
      return false;
    }),
    submissionTracking: appState.submissionTracking.filter((element) => {
      if (
        compareAsc(parseISO(element.date), dialogRange.left.startDate!) >= 0 &&
        compareAsc(parseISO(element.date), dialogRange.left.endDate!) <= 0
      ) {
        return true;
      }
      return false;
    }),
  };

  // filtered state for the right chart in modal dialog
  const rightFilteredAppState = {
    ...appState,
    sessions: appState.sessions.filter((element) => {
      if (
        compareAsc(parseISO(element.date), dialogRange.right.startDate!) >= 0 &&
        compareAsc(parseISO(element.date), dialogRange.right.endDate!) <= 0
      ) {
        return true;
      }
      return false;
    }),
    submissionTracking: appState.submissionTracking.filter((element) => {
      if (
        compareAsc(parseISO(element.date), dialogRange.right.startDate!) >= 0 &&
        compareAsc(parseISO(element.date), dialogRange.right.endDate!) <= 0
      ) {
        return true;
      }
      return false;
    }),
  };

  // data for left chart in modal dialog
  const leftSessionTypePieData =
    sessionTypePieDataGenerator(leftFilteredAppState);
  const leftPositionsFocusInSessionsData =
    positionsFocusInSessionsDataGenerator(leftFilteredAppState);
  const leftMovesFocusInSessionsData =
    movesFocusInSessionsDataGenerator(leftFilteredAppState);
  const leftMovesSkillAreaFocusData =
    movesSkillAreaFocusDataGenerator(leftFilteredAppState);
  const leftSubmissionTrackingData =
    submissionTrackingDataGenerator(leftFilteredAppState);

  // data for right chart in modal dialog
  const rightSessionTypePieData = sessionTypePieDataGenerator(
    rightFilteredAppState
  );
  const rightPositionsFocusInSessionsData =
    positionsFocusInSessionsDataGenerator(rightFilteredAppState);
  const rightMovesFocusInSessionsData = movesFocusInSessionsDataGenerator(
    rightFilteredAppState
  );
  const rightMovesSkillAreaFocusData = movesSkillAreaFocusDataGenerator(
    rightFilteredAppState
  );
  const rightSubmissionTrackingData = submissionTrackingDataGenerator(
    rightFilteredAppState
  );
  return (
    <Dialog fullScreen open={openDialog} onClose={handleClose}>
      <div
        style={{
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
        }}
      >
        <div style={{ marginTop: "5px", marginBottom: "5px" }}>
          <Button variant="contained" color="primary" onClick={handleClose}>
            Close
          </Button>
        </div>
        <div
          style={{
            display: "flex",
            flexDirection: "row",
            justifyContent: "space-around",
            width: "100vw",
          }}
        >
          <Select
            variant="outlined"
            value={visibleChart}
            onChange={(event) => {
              setVisibleChart(event.target.value as number);
            }}
            label="Chart"
          >
            <MenuItem value={0}>Type of Training</MenuItem>
            <MenuItem value={1}>Skills Focus in Sessions</MenuItem>
            <MenuItem value={2}>Submission Tracker</MenuItem>
            <MenuItem value={3}>Position Focus in Sessions</MenuItem>
            <MenuItem value={4}>Moves Focus in Sessions</MenuItem>
          </Select>
        </div>
        <div>
          <div
            style={{
              display: "flex",
              flexDirection: "row",
              justifyContent: "space-around",
              width: "100vw",
            }}
          >
            <div>
              <Select
                variant="outlined"
                defaultValue={10}
                onChange={(event) => {
                  switch (event.target.value) {
                    case 10:
                      setDialogRange({
                        ...dialogRange,
                        left: {
                          startDate: new Date(minDate),
                          endDate: new Date(),
                        },
                      });
                      break;
                    case 20:
                      setDialogRange({
                        ...dialogRange,
                        left: {
                          startDate: addMonths(new Date(), -3),
                          endDate: new Date(),
                        },
                      });
                      break;
                    case 30:
                      setDialogRange({
                        ...dialogRange,
                        left: {
                          startDate: addMonths(new Date(), -6),
                          endDate: new Date(),
                        },
                      });
                      break;
                    case 40:
                      setDialogRange({
                        ...dialogRange,
                        left: {
                          startDate: addMonths(new Date(), -12),
                          endDate: new Date(),
                        },
                      });
                      break;
                  }
                }}
                label="Period"
              >
                <MenuItem value={10}>All-Time</MenuItem>
                <MenuItem value={20}>Last 3 Months</MenuItem>
                <MenuItem value={30}>Last 6 Months</MenuItem>
                <MenuItem value={40}>Last 12 Months</MenuItem>
              </Select>
            </div>
            <div>
              <Select
                variant="outlined"
                defaultValue={10}
                onChange={(event) => {
                  switch (event.target.value) {
                    case 10:
                      setDialogRange({
                        ...dialogRange,
                        right: {
                          startDate: new Date(minDate),
                          endDate: new Date(),
                        },
                      });
                      break;
                    case 20:
                      setDialogRange({
                        ...dialogRange,
                        right: {
                          startDate: addMonths(new Date(), -3),
                          endDate: new Date(),
                        },
                      });
                      break;
                    case 30:
                      setDialogRange({
                        ...dialogRange,
                        right: {
                          startDate: addMonths(new Date(), -6),
                          endDate: new Date(),
                        },
                      });
                      break;
                    case 40:
                      setDialogRange({
                        ...dialogRange,
                        right: {
                          startDate: addMonths(new Date(), -12),
                          endDate: new Date(),
                        },
                      });
                      break;
                  }
                }}
                label="Period"
              >
                <MenuItem value={10}>All-Time</MenuItem>
                <MenuItem value={20}>Last 3 Months</MenuItem>
                <MenuItem value={30}>Last 6 Months</MenuItem>
                <MenuItem value={40}>Last 12 Months</MenuItem>
              </Select>
            </div>
          </div>
          <div
            style={{
              display: "flex",
              flexDirection: ipadMatches ? "column" : "row",
              justifyContent: "space-around",
              width: "100vw",
            }}
          >
            <div>
              <PieChartSessionTypeVis
                size={{
                  width: matches ? 375 : 500,
                  height: cardHeight,
                  radius: pieRadius,
                }}
                data={leftSessionTypePieData}
                visible={visibleChart === 0 ? true : false}
                typeColourAssignment={typeColourAssignment}
              />
              <PieChartMovesSkillAreaFocusVis
                size={{
                  width: matches ? 375 : 500,
                  height: cardHeight,
                  radius: pieRadius,
                }}
                data={leftMovesSkillAreaFocusData}
                visible={visibleChart === 1 ? true : false}
                skillsColourAssignment={skillsColourAssignment}
              />
              <PieChartSubmissionTrackingVis
                size={{
                  width: matches ? 375 : 500,
                  height: cardHeight,
                  radius: pieRadius,
                }}
                data={leftSubmissionTrackingData}
                visible={visibleChart === 2 ? true : false}
                movesColourAssignment={movesColourAssignment}
              />
              <PieChartPositionsFocusVis
                size={{
                  width: matches ? 375 : 500,
                  height: cardHeight,
                  radius: pieRadius,
                }}
                data={leftPositionsFocusInSessionsData}
                visible={visibleChart === 3 ? true : false}
                positionsColourAssignment={positionsColourAssignment}
              />
              <PieChartMovesFocusVis
                size={{
                  width: matches ? 375 : 500,
                  height: cardHeight,
                  radius: pieRadius,
                }}
                data={leftMovesFocusInSessionsData}
                visible={visibleChart === 4 ? true : false}
                movesColourAssignment={movesColourAssignment}
              />
            </div>
            <div>
              <PieChartSessionTypeVis
                size={{
                  width: matches ? 375 : 500,
                  height: cardHeight,
                  radius: pieRadius,
                }}
                data={rightSessionTypePieData}
                visible={visibleChart === 0 ? true : false}
                typeColourAssignment={typeColourAssignment}
              />
              <PieChartMovesSkillAreaFocusVis
                size={{
                  width: matches ? 375 : 500,
                  height: cardHeight,
                  radius: pieRadius,
                }}
                data={rightMovesSkillAreaFocusData}
                visible={visibleChart === 1 ? true : false}
                skillsColourAssignment={skillsColourAssignment}
              />
              <PieChartSubmissionTrackingVis
                size={{
                  width: matches ? 375 : 500,
                  height: cardHeight,
                  radius: pieRadius,
                }}
                data={rightSubmissionTrackingData}
                visible={visibleChart === 2 ? true : false}
                movesColourAssignment={movesColourAssignment}
              />
              <PieChartPositionsFocusVis
                size={{
                  width: matches ? 375 : 500,
                  height: cardHeight,
                  radius: pieRadius,
                }}
                data={rightPositionsFocusInSessionsData}
                visible={visibleChart === 3 ? true : false}
                positionsColourAssignment={positionsColourAssignment}
              />
              <PieChartMovesFocusVis
                size={{
                  width: matches ? 375 : 500,
                  height: cardHeight,
                  radius: pieRadius,
                }}
                data={rightMovesFocusInSessionsData}
                visible={visibleChart === 4 ? true : false}
                movesColourAssignment={movesColourAssignment}
              />
            </div>
          </div>
        </div>
      </div>
    </Dialog>
  );
};
