import React from "react";
import {
  Brush,
  CartesianGrid,
  Label,
  Line,
  LineChart,
  Tooltip,
  XAxis,
  YAxis,
} from "recharts";
import { format } from "date-fns";
import { Card, CardContent, Typography, useMediaQuery } from "@mui/material";

type LineChartCumulativeHoursVisProps = {
  size: { width: number; height: number };
  label?: { xAxisLabel: string; yAxisLabel: string };
  data: { name: string; value: number }[] | { name: number; value: number }[];
};

// Line chart component to display cummaltive session hours
export const LineChartCumulativeHoursVis: React.VFC<
  LineChartCumulativeHoursVisProps
> = ({ size, label, data }: LineChartCumulativeHoursVisProps) => {
  const matches = useMediaQuery("(max-width:414px)");
  return (
    <Card
      style={{
        width: `${size.width}px`,
        marginBottom: "1%",
        overflow: "visible",
      }}
    >
      <CardContent
        style={{
          paddingLeft: matches ? "0px" : "16px",
          paddingRight: matches ? "0px" : "16px",
        }}
      >
        <Typography color="textSecondary" gutterBottom>
          Cumulative Mat Hours
        </Typography>
        <LineChart
          width={matches ? size.width : size.width - 32}
          height={size.height + 28}
          data={data}
          margin={{
            top: 0,
            right: 90,
            left: 15,
            bottom: 0,
          }}
        >
          <CartesianGrid strokeDasharray="3 3" />
          <XAxis
            dataKey="name"
            domain={["auto", "auto"]}
            tickFormatter={(name) => {
              if (typeof name === "number" && isFinite(name)) {
                return format(new Date(name), "yyyy-MM-dd");
              } else {
                return name;
              }
            }}
            type="number"
          >
            <Label
              value={label === undefined ? undefined : label.xAxisLabel}
              position="bottom"
            />
          </XAxis>
          <YAxis domain={["auto", "auto"]}>
            <Label
              angle={-90}
              value={label === undefined ? undefined : label.yAxisLabel}
              position="insideLeft"
              style={{ textAnchor: "middle" }}
            />
          </YAxis>
          <Tooltip
            labelFormatter={(name) => {
              if (typeof name === "number" && isFinite(name)) {
                return format(new Date(name), "yyyy-MM-dd");
              } else {
                return name;
              }
            }}
            formatter={(value: any, name: any, props: any) => {
              return [`${value}`, `Cumulative Hours`];
            }}
          />
          <Line
            type="monotone"
            dataKey="value"
            stroke="#8884d8"
            activeDot={{ r: 8 }}
          />
          <Brush
            height={28}
            data={data}
            tickFormatter={(index) => {
              const name = data[index].name;
              if (typeof name === "number" && isFinite(name)) {
                return format(new Date(name), "yyyy-MM-dd");
              } else {
                return name;
              }
            }}
          />
        </LineChart>
      </CardContent>
    </Card>
  );
};
