import { useMediaQuery } from "@mui/material";
import { SubmissionTracking } from "data/queries/useSubmissionTrackings";
import MUIDataTable, { MUIDataTableColumnDef } from "mui-datatables";
import React from "react";
import useInsertData from "../../data/mutations/useInsertData";

type SubmissionDataViewLoadedProps = {
  appState: {
    submissionTracking: SubmissionTracking[];
  };
};

// view after the data has been loaded
export const SubmissionDataViewLoaded: React.VFC<
  SubmissionDataViewLoadedProps
> = ({ appState }: SubmissionDataViewLoadedProps) => {
  const matches = useMediaQuery("(max-width:1024px)");

  const { apiCall } = useInsertData<{
    submissionTrackingIDstoDelete: number[];
  }>("api/submission-trackings/bulk-delete", {
    method: "POST",
    snackSuccessMessage: "Success, row(s) were deleted!",
    snackErrorMessage: "Error, row(s) were not deleted!",
  });

  // options of columns
  // used by MUIDataTable
  const columns: MUIDataTableColumnDef[] = [
    {
      name: "id",
      label: "Submission Tracking ID",
      options: {
        display: "excluded",
      },
    },
    {
      name: "moveid",
      label: "Submission/Move ID",
      options: {
        display: "excluded",
      },
    },
    {
      name: "date",
      label: "Date",
      options: {
        filter: true,
        sort: true,
        customBodyRender: renderDate,
      },
    },
    {
      name: "name",
      label: "Submission",
      options: {
        filter: true,
        sort: true,
      },
    },
    {
      name: "number_of_executions",
      label: "Number of Submissions",
      options: {
        filter: true,
        sort: true,
      },
    },
    {
      name: "submitted_level",
      label: "Level of the Submitted",
      options: {
        filter: true,
        sort: true,
        customBodyRender: renderLevelOfSubmitted,
      },
    },
  ];

  // renders the data to be downloaded in the csv
  // used by MUIDataTable
  const onDownloadRender = (data: any): any => {
    let newData = [...data];
    newData.forEach((element: any) => {
      element.data[2] = renderDate(element.data[2]);
    });
    return newData;
  };

  // function that calls to backend to delete user selected entries
  // used by MUIDataTable
  const onRowsDelete = (rowsDeleted: {
    lookup: {
      [dataIndex: number]: boolean;
    };
    data: {
      index: number;
      dataIndex: number;
    }[];
  }): void => {
    const submissionTrackingIDstoDelete = Object.keys(rowsDeleted.lookup).map(
      (value: string) => appState.submissionTracking[parseInt(value)].id
    );
    apiCall({ submissionTrackingIDstoDelete });
  };

  return (
    <div>
      <MUIDataTable
        title={"Submissions Tracked"}
        data={appState.submissionTracking}
        columns={columns}
        options={{
          responsive: "simple",
          sortOrder: { name: "date", direction: "desc" },
          fixedHeader: true,
          tableBodyMaxHeight: matches
            ? "calc(100vh - 225px)"
            : "calc(100vh - 235px)",
          tableBodyHeight: matches
            ? "calc(100vh - 225px)"
            : "calc(100vh - 235px)",
          onDownload: (buildHead, buildBody, columns, data) => {
            return buildHead(columns) + buildBody(onDownloadRender(data));
          },
          onRowsDelete: onRowsDelete,
          print: false,
        }}
      />
    </div>
  );
};

//render functions used for dataView and also for onDownload
const renderDate = (value: string): string => value.split("T")[0];

const renderLevelOfSubmitted = (value: string): JSX.Element => {
  const colourMap: { [key: string]: string } = {
    White: "#f3f3f3",
    Blue: "#4a86e8",
    Purple: "#9900ff",
    Brown: "#783f04",
    Black: "#000000",
  };

  return (
    <div
      style={{
        backgroundColor: colourMap[value],
        color: value === "Black" ? "white" : "black",
      }}
    >
      {value}
    </div>
  );
};
