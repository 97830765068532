import React from "react";
import { PieLabelRenderProps } from "recharts";

const CATEGORICALCOLOURSARRAY = [
  "#ff0029",
  "#377eb8",
  "#66a61e",
  "#984ea3",
  "#00d2d5",
  "#ff7f00",
  "#af8d00",
  "#7f80cd",
  "#b3e900",
  "#c42e60",
  "#a65628",
  "#f781bf",
  "#8dd3c7",
  "#bebada",
  "#fb8072",
  "#80b1d3",
  "#fdb462",
  "#fccde5",
  "#bc80bd",
  "#ffed6f",
  "#c4eaff",
  "#cf8c00",
  "#1b9e77",
  "#d95f02",
  "#e7298a",
  "#e6ab02",
  "#a6761d",
  "#0097ff",
  "#00d067",
  "#000000",
  "#252525",
  "#525252",
  "#737373",
  "#969696",
  "#bdbdbd",
  "#f43600",
  "#4ba93b",
  "#5779bb",
  "#927acc",
  "#97ee3f",
  "#bf3947",
  "#9f5b00",
  "#f48758",
  "#8caed6",
  "#f2b94f",
  "#eff26e",
  "#e43872",
  "#d9b100",
  "#9d7a00",
  "#698cff",
  "#d9d9d9",
  "#00d27e",
  "#d06800",
  "#009f82",
  "#c49200",
  "#cbe8ff",
  "#fecddf",
  "#c27eb6",
  "#8cd2ce",
  "#c4b8d9",
  "#f883b0",
  "#a49100",
  "#f48800",
  "#27d0df",
  "#a04a9b",
];

// helper function for generating categorical colours e.g. piecharts, barcharts, etc
export const categoricalColours = (numberOfColours: number) => {
  return CATEGORICALCOLOURSARRAY.slice(0, numberOfColours);
};

// piechart label rendering helpers
export const renderCustomizedLabel = ({
  cx,
  cy,
  midAngle,
  innerRadius,
  outerRadius,
  startAngle,
  endAngle,
  name,
}: PieLabelRenderProps) => {
  if (
    typeof cx === "number" &&
    typeof cy === "number" &&
    typeof midAngle === "number" &&
    typeof innerRadius === "number" &&
    typeof outerRadius === "number" &&
    typeof endAngle === "number" &&
    typeof startAngle === "number"
  ) {
    const RADIAN = Math.PI / 180;
    const DELTA = 5;
    const verticalOverlapCorrectionValue = verticalOverlapCorrection(midAngle);
    const radius = innerRadius + (outerRadius - innerRadius);
    const x =
      cx +
      (radius + DELTA + verticalOverlapCorrectionValue) *
        Math.cos(-midAngle * RADIAN);
    const y =
      cy +
      (radius + DELTA + verticalOverlapCorrectionValue) *
        Math.sin(-midAngle * RADIAN);
    const sliceAngle = endAngle - startAngle;
    if (sliceAngle > 10) {
      return (
        <text
          // transform={
          //   x > cx
          //     ? `rotate(-${midAngle},${x},${y})`
          //     : `rotate(-${midAngle + 180},${x},${y})`
          // }
          x={x}
          y={y}
          fill="#000000"
          textAnchor={x > cx ? "start" : "end"}
          dominantBaseline="central"
          fontWeight="normal"
        >
          {name}
        </text>
      );
    }
  }
  return;
};
export const renderCustomizedLabelLine = ({
  cx,
  cy,
  midAngle,
  innerRadius,
  outerRadius,
  startAngle,
  endAngle,
}: PieLabelRenderProps) => {
  if (
    typeof cx === "number" &&
    typeof cy === "number" &&
    typeof midAngle === "number" &&
    typeof innerRadius === "number" &&
    typeof outerRadius === "number" &&
    typeof endAngle === "number" &&
    typeof startAngle === "number"
  ) {
    const RADIAN = Math.PI / 180;
    const DELTA = 5;
    const verticalOverlapCorrectionValue = verticalOverlapCorrection(midAngle);
    const radius = innerRadius + (outerRadius - innerRadius);
    const x1 = cx + radius * Math.cos(-midAngle * RADIAN);
    const y1 = cy + radius * Math.sin(-midAngle * RADIAN);
    const x2 =
      cx +
      (radius + DELTA + verticalOverlapCorrectionValue) *
        Math.cos(-midAngle * RADIAN);
    const y2 =
      cy +
      (radius + DELTA + verticalOverlapCorrectionValue) *
        Math.sin(-midAngle * RADIAN);

    const sliceAngle = endAngle - startAngle;
    if (sliceAngle > 10) {
      return (
        <line x1={x1} y1={y1} x2={x2} y2={y2} stroke="#000000" fill="none" />
      );
    }
  }
  return <line />;
};
const verticalOverlapCorrection = (midAngle: number) => {
  // provides a correction factor for the vertical direction
  // to minimise the chance of overlap of labels in piecharts
  const RADIAN = Math.PI / 180;
  const exponent = 7;
  return midAngle <= 180
    ? 45 * Math.pow(Math.sin(midAngle * RADIAN), exponent)
    : 45 * Math.pow(-Math.sin(midAngle * RADIAN), exponent);
};

// convert Date object to iso date taking into account timezone, .toISOString() only returns date in UTC
export const dateToISOLikeButLocal = (date: Date) => {
  const offsetMs = date.getTimezoneOffset() * 60 * 1000;
  const msLocal = date.getTime() - offsetMs;
  const dateLocal = new Date(msLocal);
  const iso = dateLocal.toISOString();
  const isoLocal = iso.slice(0, 19);
  return isoLocal;
};

// used for sorting objects (typically positions and moves) with a name property
// sorts alphabetical
export const sortHelper = (a: { name: string }, b: { name: string }) => {
  const aName = a.name.toLowerCase();
  const bName = b.name.toLowerCase();

  if (aName < bName) {
    return -1;
  }
  if (aName > bName) {
    return 1;
  }
  // names must be equal
  return 0;
};

// used for sorting objects with a data property
export const sortDateHelper = (
  a: { date: string | number | Date },
  b: { date: string | number | Date }
) => {
  const aDate = new Date(a.date);
  const bDate = new Date(b.date);
  if (aDate.getTime() === bDate.getTime()) {
    return 0;
  } else if (aDate > bDate) {
    return 1;
  } else {
    return -1;
  }
};
