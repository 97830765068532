import React, { useContext } from "react";
import { AuthContext } from "../../App";
import "./Logout.css";
import { Button, Container, Paper, Typography } from "@mui/material";

type LogoutProps = {};

export const Logout: React.VFC<LogoutProps> = () => {
  const authenticationState = useContext(AuthContext);

  if (authenticationState.isLoggedIn) {
    return (
      <div>
        <Container maxWidth="md">
          <Paper id="logoutContainer" elevation={3} className="paper">
            <Button
              variant="contained"
              color="info"
              onClick={authenticationState.logout}
            >
              Logout
            </Button>
          </Paper>
        </Container>
      </div>
    );
  } else {
    return (
      <div>
        <Container maxWidth="md">
          <Paper id="logoutContainer" elevation={3} className="paper">
            <Typography variant="h4">See you later!</Typography>
            <br />
            <Typography variant="h5">Logging you out...</Typography>
          </Paper>
        </Container>
      </div>
    );
  }
};
