import { AuthContext } from "App";
import axios from "axios";
import { useContext } from "react";
import { useQuery } from "react-query";

export type SubmissionTracking = {
  id: number;
  name: string;
  date: string;
  moveid: number;
  number_of_executions: number;
  submitted_level: string;
};

const getSessions = async (
  getToken: () => Promise<string | null>,
  signal?: AbortSignal
) => {
  const tokenValue = await getToken();

  return (
    await axios.request<SubmissionTracking[]>({
      url: "/api/submission-trackings",
      method: "GET",
      headers: {
        Authorization: `Bearer ${tokenValue}`,
      },
      signal,
    })
  ).data;
};

const useSessions = () => {
  const authenticationState = useContext(AuthContext);
  return useQuery(["submission-trackings"], ({ signal }) =>
    getSessions(authenticationState.getToken, signal)
  );
};

export default useSessions;
