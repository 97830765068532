import {
  Container,
  Paper,
  Typography,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  Card,
  CardContent,
  Button,
  Autocomplete,
} from "@mui/material";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import { DatePicker, LocalizationProvider } from "@mui/x-date-pickers";
import { TextField } from "@mui/material";
import React from "react";
import {
  LoggingAppData,
  LoggingAppState,
  LoggingFormValuesType,
} from "./Logging";

type LoggingLoadedProps = {
  appData: LoggingAppData;
  setAppState: React.Dispatch<React.SetStateAction<LoggingAppState>>;
  formValues: LoggingFormValuesType;
  setFormValues: React.Dispatch<React.SetStateAction<LoggingFormValuesType>>;
  onSubmit: (event: React.FormEvent) => void;
  isSubmitting: boolean;
};

// View when data has been retrieved from the database
export const LoggingLoaded: React.VFC<LoggingLoadedProps> = ({
  appData,
  setAppState,
  formValues,
  setFormValues,
  onSubmit,
  isSubmitting,
}: LoggingLoadedProps) => {
  return (
    <>
      <Container maxWidth="md" id="loggingView">
        <Paper elevation={3}>
          <LocalizationProvider dateAdapter={AdapterDateFns}>
            <form
              autoComplete="off"
              onSubmit={onSubmit}
              onKeyPress={(e) => {
                e.key === "Enter" && e.preventDefault();
              }}
              id="loggingForm"
            >
              <Typography variant="h4">Log Training Session</Typography>
              <DatePicker
                inputFormat="dd/MM/yyyy"
                label="Date of Session"
                value={formValues.date}
                renderInput={(params) => (
                  <TextField
                    id="date-of-session"
                    variant="standard"
                    required
                    {...params}
                  />
                )}
                onChange={(date) =>
                  setFormValues({ ...formValues, date: date! })
                }
              />

              <Autocomplete
                autoSelect
                freeSolo
                onChange={(e, newval, reason) => {
                  setFormValues({
                    ...formValues,
                    sessionName: newval!,
                  });
                }}
                value={formValues.sessionName}
                options={appData.sessionNames
                  .filter(
                    (value) => value.day_of_week === formValues.date.getDay()
                  )
                  .map((value) => value.name)}
                getOptionLabel={(sessionName) => sessionName}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    variant="standard"
                    label="Session"
                    placeholder=""
                    required
                  />
                )}
              />

              <FormControl variant="standard">
                <InputLabel shrink id="mode-of-training">
                  Mode
                </InputLabel>
                <Select
                  required
                  value={formValues.trainingMode}
                  onChange={(event) =>
                    setFormValues({
                      ...formValues,
                      trainingMode: event.target.value as string,
                    })
                  }
                >
                  <MenuItem value="Learning">Learning</MenuItem>
                  <MenuItem value="Teaching">Teaching</MenuItem>
                </Select>
              </FormControl>

              <FormControl variant="standard">
                <InputLabel shrink id="type">
                  Type
                </InputLabel>
                <Select
                  required
                  value={formValues.trainingType}
                  onChange={(event) =>
                    setFormValues({
                      ...formValues,
                      trainingType: event.target.value as string,
                    })
                  }
                >
                  <MenuItem value="Gi">Gi</MenuItem>
                  <MenuItem value="No Gi">No Gi</MenuItem>
                  <MenuItem value="Unspecified">Unspecified</MenuItem>
                </Select>
              </FormControl>

              <TextField
                required
                label="Duration"
                type="number"
                variant="standard"
                InputProps={{
                  inputProps: {
                    min: "0",
                    step: "0.25",
                    style: { textAlign: "center" },
                  },
                }}
                InputLabelProps={{
                  shrink: true,
                }}
                value={formValues.duration}
                onChange={(event) =>
                  setFormValues({
                    ...formValues,
                    duration: parseFloat(event.target.value),
                  })
                }
              />

              <TextField
                style={{ marginTop: "10px", marginBottom: "10px" }}
                label="Training Notes"
                multiline
                rows={6}
                value={formValues.notes}
                onChange={(event) =>
                  setFormValues({
                    ...formValues,
                    notes: event.target.value,
                  })
                }
                variant="outlined"
              />

              <Autocomplete
                multiple
                freeSolo
                autoSelect
                filterSelectedOptions
                value={formValues.positions}
                onChange={(e, newval, reason) => {
                  setFormValues({
                    ...formValues,
                    positions: newval,
                  });
                }}
                options={appData.positions.map((value) => value.name)}
                getOptionLabel={(position) => position}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    variant="standard"
                    label="Session Focus Positions"
                    placeholder="Positions"
                  />
                )}
              />

              <Autocomplete
                multiple
                freeSolo
                autoSelect
                filterSelectedOptions
                value={formValues.moves}
                onChange={(e, newval, reason) => {
                  setFormValues({
                    ...formValues,
                    moves: newval,
                  });
                }}
                options={appData.moves.map((value) => value.name)}
                getOptionLabel={(move) => move}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    variant="standard"
                    label="Session Focus Moves"
                    placeholder="Moves"
                  />
                )}
              />
              <Card
                variant="outlined"
                style={{ marginTop: "10px", marginBottom: "10px" }}
              >
                <CardContent>
                  <TextField
                    label="Number of Rolling Rounds"
                    type="number"
                    variant="standard"
                    InputProps={{
                      inputProps: {
                        min: "0",
                        step: "1",
                        style: { textAlign: "center" },
                      },
                    }}
                    InputLabelProps={{
                      shrink: true,
                    }}
                    value={formValues.numberOfRollingRounds}
                    onChange={(event) =>
                      setFormValues({
                        ...formValues,
                        numberOfRollingRounds: parseInt(event.target.value),
                      })
                    }
                  />
                  <TextField
                    label="Duration of Each Round"
                    type="number"
                    variant="standard"
                    InputProps={{
                      inputProps: {
                        min: "0",
                        step: "1",
                        style: { textAlign: "center" },
                      },
                    }}
                    InputLabelProps={{
                      shrink: true,
                    }}
                    value={formValues.rollingRoundDuration}
                    onChange={(event) =>
                      setFormValues({
                        ...formValues,
                        rollingRoundDuration: parseInt(event.target.value),
                      })
                    }
                  />
                </CardContent>
              </Card>

              <Card variant="outlined">
                <CardContent>
                  <Autocomplete
                    autoSelect
                    onChange={(e, newval, reason) => {
                      setFormValues({
                        ...formValues,
                        situationalSparringPosition: newval,
                      });
                    }}
                    value={formValues.situationalSparringPosition}
                    options={appData.positions.map((value) => value.name)}
                    getOptionLabel={(position) => position}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        variant="standard"
                        label="Situational Sparring Position"
                        placeholder="Position"
                      />
                    )}
                  />
                  <div style={{ marginTop: "10px", marginBottom: "10px" }}>
                    <TextField
                      label="Number of Situational Sparring Rounds"
                      type="number"
                      variant="standard"
                      InputProps={{
                        inputProps: {
                          min: "0",
                          step: "1",
                          style: { textAlign: "center" },
                        },
                      }}
                      InputLabelProps={{
                        shrink: true,
                      }}
                      value={formValues.numberOfSituationalRounds}
                      onChange={(event) =>
                        setFormValues({
                          ...formValues,
                          numberOfSituationalRounds: parseInt(
                            event.target.value
                          ),
                        })
                      }
                    />
                    <TextField
                      label="Duration of Each Round"
                      type="number"
                      variant="standard"
                      InputProps={{
                        inputProps: {
                          min: "0",
                          step: "1",
                          style: { textAlign: "center" },
                        },
                      }}
                      InputLabelProps={{
                        shrink: true,
                      }}
                      value={formValues.situationalRoundDuration}
                      onChange={(event) =>
                        setFormValues({
                          ...formValues,
                          situationalRoundDuration: parseInt(
                            event.target.value
                          ),
                        })
                      }
                    />
                  </div>
                </CardContent>
              </Card>

              <TextField
                style={{ marginTop: "10px", marginBottom: "10px" }}
                label="Injury Notes"
                multiline
                rows={4}
                value={formValues.injuryNote}
                onChange={(event) =>
                  setFormValues({
                    ...formValues,
                    injuryNote: event.target.value,
                  })
                }
                variant="outlined"
              />
              <div id="loggingButtonDiv">
                <Button
                  color="primary"
                  type="submit"
                  variant="contained"
                  disabled={isSubmitting}
                >
                  Log
                </Button>
              </div>
            </form>
          </LocalizationProvider>
        </Paper>
        <br />
        <Button
          color="primary"
          variant="contained"
          onClick={() => setAppState({ state: "import" })}
        >
          Import Data from CSV
        </Button>
      </Container>
    </>
  );
};
