import useMoves from "data/queries/useMoves";
import React from "react";
import Loading from "../../components/Loading";
import ErrorComponent from "../../components/ErrorComponent";
import { DataViewLoaded } from "./DataViewLoaded";
import usePositions from "../../data/queries/usePositions";
import useSessionNames from "data/queries/useSessionNames";
import useSessions from "data/queries/useSessions";

type DataViewProps = {};

// component used to display a table of the user's logged information from Logging
// loading data
export const DataView: React.VFC<DataViewProps> = () => {
  const moves = useMoves();
  const positions = usePositions();
  const sessionNames = useSessionNames();
  const sessions = useSessions();

  if (
    moves.isLoading ||
    positions.isLoading ||
    sessionNames.isLoading ||
    sessions.isLoading ||
    moves.isIdle ||
    positions.isIdle ||
    sessionNames.isIdle ||
    sessions.isIdle
  ) {
    return <Loading />;
  }
  if (
    moves.isError ||
    positions.isError ||
    sessionNames.isError ||
    sessions.isError
  ) {
    return <ErrorComponent />;
  }

  const appState = {
    moves: moves.data,
    positions: positions.data,
    sessionNames: sessionNames.data,
    sessions: sessions.data,
  };

  return <DataViewLoaded appState={appState} />;
};
