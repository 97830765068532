import { Button, Container, Divider, Paper, Typography } from "@mui/material";
import React, { useContext } from "react";
import { AuthContext } from "../../App";
import { generatePath, useNavigate } from "react-router-dom";
import Loading from "../../components/Loading";
import ErrorComponent from "../../components/ErrorComponent";
import "./UserProfile.css";
import { formatDateString } from "../../utils/formatDateString";
import useMe from "data/queries/useMe";
import useInsertData from "../../data/mutations/useInsertData";

interface UpdateSharedStatusType {
  shared: boolean;
  getToken: () => Promise<string | null>;
}

type UserProfileProps = {
  viewStates: ViewState[];
};

// user profile view
// displays information of the user and also provides links to options/settings that can be changed for the user
export const UserProfile: React.VFC<UserProfileProps> = ({
  viewStates,
}: UserProfileProps) => {
  const authenticationState = useContext(AuthContext);
  const navigate = useNavigate();
  const [sharePublicData, setSharePublicData] = React.useState(false);
  const me = useMe();

  const { apiCall, isLoading } = useInsertData<{
    share_public_data: boolean;
  }>("/api/users/profile", {
    method: "PATCH",
    onSuccessCallback(response, variables, context) {
      setSharePublicData(response.data.share_public_data);
    },
  });

  const onChangeSharePublicDataCheckbox = async (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    apiCall({ share_public_data: event.target.checked });
  };

  React.useEffect(() => {
    setSharePublicData(me.data?.share_public_data ?? false);
  }, [me.data?.share_public_data]);

  if (me.isLoading || me.isIdle) {
    return <Loading />;
  }
  if (me.isError) {
    return <ErrorComponent />;
  }

  const otherOptionComponents = viewStates
    .filter((value) => value.inUserProfile)
    .map((value) => {
      return (
        <Button
          key={value.key}
          color="primary"
          variant="contained"
          startIcon={value.icon ? value.icon : undefined}
          onClick={() => navigate(value.routeLocation)}
          style={{ marginTop: "5px" }}
        >
          {`${value.displayName}`}
        </Button>
      );
    });

  return (
    <Container maxWidth="md">
      <Paper elevation={3} className="paper">
        <Typography variant="h5" gutterBottom>
          User Information
        </Typography>
        <table>
          <tbody>
            <tr>
              <td className="attributeCol">Username:</td>
              <td>{authenticationState.username}</td>
            </tr>
            <tr>
              <td className="attributeCol">Email Address:</td>
              <td></td>
            </tr>
            <tr>
              <td className="attributeCol">First Name:</td>
              <td></td>
            </tr>
            <tr>
              <td className="attributeCol">Last Name:</td>
              <td></td>
            </tr>
            <tr>
              <td className="attributeCol">Date Registered:</td>
              <td>{formatDateString(me.data.created_at)}</td>
            </tr>
            <tr>
              <td className="attributeCol">Last Login:</td>
              <td>{formatDateString(me.data.last_login)}</td>
            </tr>
            <tr>
              <td className="attributeCol">Show public info:</td>
              <td>
                {
                  <input
                    type="checkbox"
                    checked={sharePublicData}
                    onChange={onChangeSharePublicDataCheckbox}
                    disabled={isLoading}
                  />
                }
              </td>
            </tr>
            {sharePublicData && (
              <tr>
                <td className="attributeCol"></td>
                <td>
                  <a
                    href={generatePath("/shared/:username", {
                      username: me.data.username,
                    })}
                  >
                    Share this Link
                  </a>
                </td>
              </tr>
            )}
          </tbody>
        </table>

        <Divider />
        <br />
        <Typography variant="h5" gutterBottom>
          Other Options
        </Typography>
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
          }}
        >
          {otherOptionComponents}
        </div>
      </Paper>
    </Container>
  );
};
