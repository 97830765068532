import { Container, Paper, Typography, Button, TextField } from "@mui/material";
import { Move } from "data/queries/useMoves";
import { useSnackbar } from "notistack";
import React, { useState } from "react";
import CSVReader from "react-csv-reader";
import { Link } from "react-router-dom";
import { SubmissionTrackingViewState } from "./SubmissionTracking";

type SubmissionTrackingImportViewProps = {
  appState: {
    moves: Move[];
  };
  setAppState: React.Dispatch<
    React.SetStateAction<SubmissionTrackingViewState>
  >;
  apiInsertSubmissionCall: (
    sanitisedFormData: {
      date: string;
      submission: string;
      number: number;
      opponentLevel: string;
    }[]
  ) => any;
  isSubmitting: boolean;
};

// view for importing data from a csv file, should be formatted the same as when downloaded from the
// renders the data to be downloaded in the csv
export const SubmissionTrackingImportView: React.VFC<
  SubmissionTrackingImportViewProps
> = ({
  appState,
  setAppState,
  apiInsertSubmissionCall,
  isSubmitting,
}: SubmissionTrackingImportViewProps) => {
  const { enqueueSnackbar } = useSnackbar();
  type CsvDataType = {
    Date: string;
    Submission: string;
    "Number of Submissions": string;
    "Level of the Submitted": string;
    "User ID": string;
  };
  const initialCsvData: CsvDataType[] = [];
  const [csvData, setCsvData] = useState(initialCsvData);

  let errorLogs = "";

  const importData = async () => {
    //validate data
    if (
      !csvData.every((row) => {
        return (
          //determine if there is a way to check type of with csvDataType, typeof does not work
          row.hasOwnProperty("Date") &&
          row.hasOwnProperty("Submission") &&
          row.hasOwnProperty("Number of Submissions") &&
          !isNaN(parseInt(row["Number of Submissions"])) &&
          row.hasOwnProperty("Level of the Submitted")
        );
      })
    ) {
      enqueueSnackbar("Error, csv was not formatted correctly!", {
        variant: "error",
      });
      return;
    }

    // format data as expected
    const dataToSubmit = csvData.map((row) => {
      return {
        date: row.Date,
        submission: row.Submission,
        number: parseInt(row["Number of Submissions"]),
        opponentLevel: row["Level of the Submitted"],
      };
    });

    await apiInsertSubmissionCall(dataToSubmit).then((response: any) => {
      if (response.hasOwnProperty("error")) {
        errorLogs += `${response.error}\n`;
      }
    });
  };

  return (
    <Container maxWidth="md">
      <Paper elevation={3} className="paper">
        <Typography variant="h4">Import Submission Data via CSV</Typography>
        <br />
        <Typography variant="body1">
          Data in CSV must be formatted the same as the downloaded CSV from the{" "}
          <Link to="/">TBA</Link> table.
        </Typography>
        <br />
        <CSVReader
          parserOptions={{ header: true }}
          onFileLoaded={(data) => setCsvData(data)}
        />
        <br />
        <Button
          color="primary"
          type="submit"
          variant="contained"
          onClick={importData}
          disabled={isSubmitting}
        >
          Import Data
        </Button>
        <br />
        <br />
        <TextField
          id="outlined-multiline-static"
          label="Import Errors:"
          value={errorLogs}
          multiline
          rows={10}
          defaultValue=""
          variant="outlined"
          disabled={true}
          style={{ width: "100%" }}
        />
      </Paper>
      <br />
      <Button
        color="primary"
        variant="contained"
        onClick={() => setAppState({ ...appState, state: "loaded" })}
        disabled={isSubmitting}
      >
        Back
      </Button>
    </Container>
  );
};
