import { Chip, useMediaQuery } from "@mui/material";
import { Move } from "data/queries/useMoves";
import { Position } from "data/queries/usePositions";
import { SessionName } from "data/queries/useSessionNames";
import { Session } from "data/queries/useSessions";
import MUIDataTable, { MUIDataTableColumnDef } from "mui-datatables";
import React, { useContext } from "react";
import { AuthContext } from "../../App";
import useInsertData from "../../data/mutations/useInsertData";

type DataViewLoadedProps = {
  appState: {
    positions: Position[];
    moves: Move[];
    sessionNames: SessionName[];
    sessions: Session[];
  };
};

// View displayed when data has been loaded
export const DataViewLoaded: React.VFC<DataViewLoadedProps> = ({
  appState,
}: DataViewLoadedProps) => {
  const authenticationState = useContext(AuthContext);
  const matches = useMediaQuery("(max-width:1024px)");

  const { apiCall } = useInsertData<{ sessionIDstoDelete: number[] }>(
    "api/sessions/bulk-delete",
    {
      method: "POST",
      snackSuccessMessage: "Success, row(s) were deleted!",
      snackErrorMessage: "Error, row(s) were not deleted!",
    }
  );

  const columns: MUIDataTableColumnDef[] = [
    // define column options of MUIDataTable
    {
      name: "id",
      label: "Session ID",
      options: {
        display: "excluded",
      },
    },
    {
      name: "date",
      label: "Date",
      options: {
        filter: true,
        sort: true,
        customBodyRender: renderDate,
      },
    },
    {
      name: "session",
      label: "Class",
      options: {
        filter: true,
        sort: true,
        customBodyRender: (value: string) =>
          renderSessionNameTable(value, appState.sessionNames),
      },
    },
    {
      name: "mode",
      label: "Mode",
      options: {
        filter: true,
        sort: true,
      },
    },
    {
      name: "type",
      label: "Type",
      options: {
        filter: true,
        sort: true,
      },
    },
    {
      name: "duration",
      label: "Duration",
      options: {
        filter: true,
        sort: true,
      },
    },
    {
      name: "training_notes",
      label: "Training Notes",
      options: {
        filter: true,
        sort: true,
      },
    },
    {
      name: "positions",
      label: "Positions",
      options: {
        filter: true,
        sort: true,
        customBodyRender: renderPositions,
      },
    },
    {
      name: "moves",
      label: "Moves",
      options: {
        filter: true,
        sort: true,
        customBodyRender: renderMoves,
      },
    },
    {
      name: "num_rolling_rounds",
      label: "No. Rolling Rounds",
      options: {
        filter: true,
        sort: true,
        display: false,
      },
    },
    {
      name: "duration_rolling_rounds",
      label: "Rolling Rounds Duration",
      options: {
        filter: true,
        sort: true,
        display: false,
      },
    },
    {
      name: "situational_position",
      label: "Situational Sparring Position",
      options: {
        filter: true,
        sort: true,
        customBodyRender: (value: string) =>
          renderSituationalSparringPosition(value, appState.positions),
        display: false,
      },
    },
    {
      name: "num_situational_rounds",
      label: "No. Situational Rounds",
      options: {
        filter: true,
        sort: true,
        display: false,
      },
    },
    {
      name: "duration_situational_rounds",
      label: "Situational Rounds Duration",
      options: {
        filter: true,
        sort: true,
        display: false,
      },
    },
    {
      name: "injury_notes",
      label: "Injury Notes",
      options: {
        filter: true,
        sort: true,
      },
    },
    {
      name: "userid",
      label: "User ID",
      options: {
        display: "excluded",
      },
    },
  ];

  // onDownloadRender used to render the data when data exported as csv
  // used by MUIDataTable
  const onDownloadRender = (data: any): any => {
    let newData = [...data];
    newData.forEach((element: any) => {
      element.data[1] = renderDate(element.data[1]);
      element.data[2] = renderSessionName(
        element.data[2],
        appState.sessionNames
      );
      element.data[11] = renderSituationalSparringPosition(
        element.data[11],
        appState.positions
      );
      element.data[15] = authenticationState.username;
    });
    return newData;
  };

  // API calls used to delete sessions recorded
  // used by MUIDataTable
  const onRowsDelete = (rowsDeleted: {
    lookup: {
      [dataIndex: number]: boolean;
    };
    data: {
      index: number;
      dataIndex: number;
    }[];
  }): void => {
    const sessionIDstoDelete = Object.keys(rowsDeleted.lookup).map(
      (value: string) => appState.sessions[parseInt(value)].id
    );

    apiCall({
      sessionIDstoDelete: sessionIDstoDelete,
    });
  };

  return (
    <div>
      <MUIDataTable
        title={"Logged Training Sessions"}
        data={appState.sessions.map((sessionObject) => ({
          ...sessionObject,
          positions: sessionObject.positions.map((value) => value.name),
          moves: sessionObject.moves.map((value) => value.name),
        }))}
        columns={columns}
        options={{
          responsive: "simple",
          sortOrder: { name: "date", direction: "desc" },
          fixedHeader: true,
          tableBodyMaxHeight: matches
            ? "calc(100vh - 225px)"
            : "calc(100vh - 235px)",
          tableBodyHeight: matches
            ? "calc(100vh - 225px)"
            : "calc(100vh - 235px)",
          onDownload: (buildHead, buildBody, columns, data) => {
            return buildHead(columns) + buildBody(onDownloadRender(data));
          },
          onRowsDelete: onRowsDelete,
          print: false,
        }}
      />
    </div>
  );
};

//render functions used for dataView and also for onDownload
const renderDate = (value: string): string => value.split("T")[0];

const renderSessionName = (
  value: string,
  sessionNames: SessionName[]
): string => {
  const sessionName = sessionNames.find(
    (session) => session.id === parseInt(value)
  );
  return sessionName!.name;
};

const renderSessionNameTable = (
  value: string,
  sessionNames: SessionName[]
): JSX.Element => {
  const sessionName = sessionNames.find(
    (session) => session.id === parseInt(value)
  );
  const colourOfTheWeek = [
    "#9900ff",
    "#cc0000",
    "#6d9eeb",
    "#ffd966",
    "#a64d79",
    "#6aa84f",
    "#e69138",
  ];
  sessionName?.day_of_week;
  return (
    <div
      style={{
        backgroundColor: colourOfTheWeek[sessionName!.day_of_week],
      }}
    >
      {sessionName!.name}
    </div>
  );
};

const renderPositions = (value: any) =>
  value.map((position: any) => (
    <Chip key={position} label={position} style={{ margin: "2px" }} />
  ));

const renderMoves = (value: any) =>
  value.map((move: any) => (
    <Chip key={move} label={move} style={{ margin: "2px" }} />
  ));

const renderSituationalSparringPosition = (
  value: string,
  positions: Position[]
): string => {
  const situationPosition = positions.find(
    (position) => position.id === parseInt(value)
  );
  if (situationPosition) {
    return situationPosition.name;
  } else {
    return "";
  }
};
