import { CircularProgress, Typography } from "@mui/material";
import React from "react";

// General loading screen used when data is being retrieved from the backend
// used because fetch is an asynchronous call
const Loading = () => {
  return (
    <div
      style={{
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
      }}
    >
      <br />
      <Typography variant="h2">Loading Data</Typography>
      <br />
      <CircularProgress variant="indeterminate" size={100} thickness={4} />
    </div>
  );
};

export default Loading;