import { Container, Paper, Typography, Button } from "@mui/material";
import { useSnackbar } from "notistack";
import React, { useState } from "react";
import CSVReader from "react-csv-reader";
import { Link } from "react-router-dom";
import { LoggingAppState, SanitisedLoggingFormDataType } from "./Logging";

type LoggingImportViewProps = {
  setAppState: React.Dispatch<LoggingAppState>;
  apiInsertSessionCall: (
    sanitisedFormData: SanitisedLoggingFormDataType
  ) => void;
  isSubmitting: boolean;
};

// View for importing session data from a csv
export const LoggingImportView: React.VFC<LoggingImportViewProps> = ({
  setAppState,
  apiInsertSessionCall,
  isSubmitting,
}: LoggingImportViewProps) => {
  type CsvDataType = {
    Class: string;
    Date: string;
    Duration: string;
    "Injury Notes": string;
    Mode: string;
    Moves: string;
    "No. Rolling Rounds": string;
    "No. Situational Rounds": string;
    Positions: string;
    "Rolling Rounds Duration": string;
    "Session ID": string;
    "Situational Rounds Duration": string;
    "Situational Sparring Position": string;
    "Training Notes": string;
    Type: string;
    "User ID": string;
  };
  const initialCsvData: CsvDataType[] = [];
  const [csvData, setCsvData] = useState(initialCsvData);
  const { enqueueSnackbar } = useSnackbar();

  // function called to import the data, current method is a "hack"
  // for each row, sets the form data and then submits it programmatically
  // future improvement is not use that hack and submit all csv data
  // as JSON to backend to handle importing
  const importData = () => {
    //validate data
    if (
      !csvData.every((row) => {
        return (
          //determine if there is a way to check type of with csvDataType, typeof does not work
          row.hasOwnProperty("Class") &&
          row.hasOwnProperty("Date") &&
          row.hasOwnProperty("Duration") &&
          row.hasOwnProperty("Injury Notes") &&
          row.hasOwnProperty("Mode") &&
          row.hasOwnProperty("Moves") &&
          row.hasOwnProperty("No. Rolling Rounds") &&
          !isNaN(parseInt(row["No. Rolling Rounds"])) &&
          row.hasOwnProperty("No. Situational Rounds") &&
          !isNaN(parseInt(row["No. Situational Rounds"])) &&
          row.hasOwnProperty("Positions") &&
          row.hasOwnProperty("Rolling Rounds Duration") &&
          !isNaN(parseInt(row["Rolling Rounds Duration"])) &&
          row.hasOwnProperty("Session ID") &&
          row.hasOwnProperty("Situational Rounds Duration") &&
          !isNaN(parseInt(row["Situational Rounds Duration"])) &&
          row.hasOwnProperty("Situational Sparring Position") &&
          row.hasOwnProperty("Training Notes") &&
          row.hasOwnProperty("Type") &&
          row.hasOwnProperty("User ID")
        );
      })
    ) {
      enqueueSnackbar("Error, csv was not formatted correctly!", {
        variant: "error",
      });
      return;
    }

    //for each line set data and submit the form
    for (let i = 0; i < csvData.length; i++) {
      const row = csvData[i];
      const dataToSubmit = {
        date: row.Date,
        sessionName: row.Class,
        trainingMode: row.Mode,
        trainingType: row.Type,
        duration: parseFloat(row.Duration),
        positions: row.Positions.split(",").filter((element) => element !== ""),
        moves: row.Moves.split(",").filter((element) => element !== ""),
        notes: row["Training Notes"],
        numberOfRollingRounds: parseInt(row["No. Rolling Rounds"]),
        rollingRoundDuration: parseInt(row["Rolling Rounds Duration"]),
        situationalSparringPosition:
          row["Situational Sparring Position"] === ""
            ? null
            : row["Situational Sparring Position"], //this might need to be and input of inputs
        numberOfSituationalRounds: parseInt(row["No. Situational Rounds"]),
        situationalRoundDuration: parseInt(row["Situational Rounds Duration"]),
        injuryNote: row["Injury Notes"],
      };

      apiInsertSessionCall(dataToSubmit);
    }
  };

  return (
    <Container maxWidth="md">
      <Paper elevation={3} className="paper">
        <Typography variant="h4">Import Data via CSV</Typography>
        <br />
        <Typography variant="body1">
          Data in CSV must be formatted the same as the downloaded CSV from the{" "}
          <Link to="/data-view">Data View</Link> table.
        </Typography>
        <br />
        <CSVReader
          parserOptions={{ header: true }}
          onFileLoaded={(data) => setCsvData(data)}
        />
        <br />
        <Button
          color="primary"
          type="submit"
          variant="contained"
          onClick={importData}
          disabled={isSubmitting}
        >
          Import Data
        </Button>
      </Paper>
      <br />
      <Button
        color="primary"
        variant="contained"
        onClick={() => setAppState({ state: "loaded" })}
        disabled={isSubmitting}
      >
        Back
      </Button>
    </Container>
  );
};
