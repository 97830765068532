import { Button, Container, Divider, Paper, Typography } from "@mui/material";
import { format } from "date-fns";
import React from "react";
import { useState } from "react";
import useInsertData from "../../../data/mutations/useInsertData";
import { BeltInformationFormDataType } from "./SetBeltInformation";
import { SetBeltInformationUpdateTable } from "./SetBeltInformationUpdateTable";

type SetBeltInformationLoadedProps = {
  appState: {
    beltInformation: BeltInformationType;
  };
};

// when data is loaded
export const SetBeltInformationLoaded: React.VFC<
  SetBeltInformationLoadedProps
> = ({ appState }: SetBeltInformationLoadedProps) => {
  const initialBeltInformationFormData: BeltInformationFormDataType = {
    bjjwhite0:
      appState.beltInformation.bjjwhite0 === null
        ? null
        : new Date(appState.beltInformation.bjjwhite0),
    bjjwhite1:
      appState.beltInformation.bjjwhite1 === null
        ? null
        : new Date(appState.beltInformation.bjjwhite1),
    bjjwhite2:
      appState.beltInformation.bjjwhite2 === null
        ? null
        : new Date(appState.beltInformation.bjjwhite2),
    bjjwhite3:
      appState.beltInformation.bjjwhite3 === null
        ? null
        : new Date(appState.beltInformation.bjjwhite3),
    bjjwhite4:
      appState.beltInformation.bjjwhite4 === null
        ? null
        : new Date(appState.beltInformation.bjjwhite4),
    bjjblue0:
      appState.beltInformation.bjjblue0 === null
        ? null
        : new Date(appState.beltInformation.bjjblue0),
    bjjblue1:
      appState.beltInformation.bjjblue1 === null
        ? null
        : new Date(appState.beltInformation.bjjblue1),
    bjjblue2:
      appState.beltInformation.bjjblue2 === null
        ? null
        : new Date(appState.beltInformation.bjjblue2),
    bjjblue3:
      appState.beltInformation.bjjblue3 === null
        ? null
        : new Date(appState.beltInformation.bjjblue3),
    bjjblue4:
      appState.beltInformation.bjjblue4 === null
        ? null
        : new Date(appState.beltInformation.bjjblue4),
    bjjpurple0:
      appState.beltInformation.bjjpurple0 === null
        ? null
        : new Date(appState.beltInformation.bjjpurple0),
    bjjpurple1:
      appState.beltInformation.bjjpurple1 === null
        ? null
        : new Date(appState.beltInformation.bjjpurple1),
    bjjpurple2:
      appState.beltInformation.bjjpurple2 === null
        ? null
        : new Date(appState.beltInformation.bjjpurple2),
    bjjpurple3:
      appState.beltInformation.bjjpurple3 === null
        ? null
        : new Date(appState.beltInformation.bjjpurple3),
    bjjpurple4:
      appState.beltInformation.bjjpurple4 === null
        ? null
        : new Date(appState.beltInformation.bjjpurple4),
    bjjbrown0:
      appState.beltInformation.bjjbrown0 === null
        ? null
        : new Date(appState.beltInformation.bjjbrown0),
    bjjbrown1:
      appState.beltInformation.bjjbrown1 === null
        ? null
        : new Date(appState.beltInformation.bjjbrown1),
    bjjbrown2:
      appState.beltInformation.bjjbrown2 === null
        ? null
        : new Date(appState.beltInformation.bjjbrown2),
    bjjbrown3:
      appState.beltInformation.bjjbrown3 === null
        ? null
        : new Date(appState.beltInformation.bjjbrown3),
    bjjbrown4:
      appState.beltInformation.bjjbrown4 === null
        ? null
        : new Date(appState.beltInformation.bjjbrown4),
    bjjblack0:
      appState.beltInformation.bjjblack0 === null
        ? null
        : new Date(appState.beltInformation.bjjblack0),
    bjjblack1:
      appState.beltInformation.bjjblack1 === null
        ? null
        : new Date(appState.beltInformation.bjjblack1),
    bjjblack2:
      appState.beltInformation.bjjblack2 === null
        ? null
        : new Date(appState.beltInformation.bjjblack2),
    bjjblack3:
      appState.beltInformation.bjjblack3 === null
        ? null
        : new Date(appState.beltInformation.bjjblack3),
    bjjblack4:
      appState.beltInformation.bjjblack4 === null
        ? null
        : new Date(appState.beltInformation.bjjblack4),
  };

  const [beltInformationFormData, setBeltInformationFormData] = useState(
    initialBeltInformationFormData
  );

  const { apiCall, isLoading } = useInsertData<ProfilePatchType>(
    "api/users/profile",
    {
      method: "PATCH",
    }
  );

  const updateMoves = (event: React.FormEvent) => {
    event.preventDefault();

    const sanitisedFormData: BeltInformationType = {
      ...appState.beltInformation,
    };
    Object.keys(beltInformationFormData).forEach((key) => {
      sanitisedFormData[key as keyof BeltInformationType] =
        beltInformationFormData[key as keyof BeltInformationFormDataType] ===
        null
          ? null
          : format(
              beltInformationFormData[
                key as keyof BeltInformationFormDataType
              ]!,
              "yyyy-MM-d"
            );
    });

    apiCall(sanitisedFormData);
  };

  return (
    <Container maxWidth="md" id="setMovesSkillAreaView">
      <Paper
        elevation={3}
        style={{
          paddingTop: "20px",
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
        }}
      >
        <Typography variant="body1" style={{ padding: "0px 20px 10px 20px" }}>
          Set the date of when your received your BJJ belts and/or Stripes{" "}
        </Typography>
        <Divider variant="middle" style={{ width: "100%" }} />
        <br />
        <SetBeltInformationUpdateTable
          appState={appState}
          beltInformationFormData={beltInformationFormData}
          setBeltInformationFormData={setBeltInformationFormData}
        />
        <br />
        <div id="setMovesSkillAreaButtonDiv" style={{ paddingBottom: "20px" }}>
          <Button
            color="primary"
            type="submit"
            variant="contained"
            onClick={updateMoves}
            disabled={isLoading}
          >
            Update
          </Button>
        </div>
      </Paper>
    </Container>
  );
};
