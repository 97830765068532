import useSubmissionTrackings from "data/queries/useSubmissionTrackings";
import React from "react";
import Loading from "../../components/Loading";
import ErrorComponent from "../../components/ErrorComponent";
import { SubmissionDataViewLoaded } from "./SubmissionDataViewLoaded";

type SubmissionDataViewProps = {};

// data table of the submission data of the user that has been logged
export const SubmissionDataView: React.VFC<SubmissionDataViewProps> = () => {
  const submissionTrackings = useSubmissionTrackings();

  if (submissionTrackings.isLoading || submissionTrackings.isIdle) {
    return <Loading />;
  }
  if (submissionTrackings.isError) {
    return <ErrorComponent />;
  }

  return (
    <SubmissionDataViewLoaded
      appState={{
        submissionTracking: submissionTrackings.data,
      }}
    />
  );
};
