import { Button, Container, Divider, Paper, Typography } from "@mui/material";
import React, { useState } from "react";
import useInsertData from "../../../data/mutations/useInsertData";
import { SetMovesSkillAreaViewStateType } from "./SetMovesSkillArea";
import { SetMovesSkillAreaUpdateTable } from "./SetMovesSkillAreaUpdateTable";
import "./SetMovesSkillAreaLoaded.css";
import { Move } from "data/queries/useMoves";

type SetMovesSkillAreaLoadedProps = {
  appState: {
    moves: Move[];
  };
};

// view when data after data has been loaded
export const SetMovesSkillAreaLoaded: React.VFC<
  SetMovesSkillAreaLoadedProps
> = ({ appState }: SetMovesSkillAreaLoadedProps) => {
  const initialSetMovesSkillAreaViewState: {
    newMoveSkillAreas: SetMovesSkillAreaViewStateType;
  } = {
    newMoveSkillAreas: appState.moves.map((entry) => {
      return {
        ...entry,
        skillarea: entry.skillarea === null ? "" : entry.skillarea,
      };
    }),
  };
  const [setMovesSkillAreaViewState, setSetMovesSkillAreaViewState] = useState(
    initialSetMovesSkillAreaViewState
  );
  const { apiCall, isLoading } = useInsertData<SetMovesSkillAreaViewStateType>(
    "api/moves/bulk-update"
  );

  const updateMoves = (event: React.FormEvent) => {
    event.preventDefault();

    // parse data for only moves with modified skill areas
    let sanitisedFormData = setMovesSkillAreaViewState.newMoveSkillAreas.map(
      (move) => {
        return {
          ...move,
          skillarea: move.skillarea === "" ? null : move.skillarea,
        };
      }
    );

    sanitisedFormData = sanitisedFormData.filter((move) => {
      const databaseData = appState.moves.find(
        (element) => element.name === move.name
      );
      if (databaseData!.skillarea !== move.skillarea) {
        return true;
      }
      return false;
    });
    apiCall(sanitisedFormData);
  };

  return (
    <Container maxWidth="md" id="setMovesSkillAreaView">
      <Paper
        elevation={3}
        style={{
          paddingTop: "20px",
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
        }}
      >
        <Typography variant="body1" style={{ padding: "0px 20px 10px 20px" }}>
          Set the skill areas associated with your moves here. These settings
          used to classify moves for the graphs found in Data Visualisation.{" "}
        </Typography>
        <Divider variant="middle" style={{ width: "100%" }} />
        <br />
        <SetMovesSkillAreaUpdateTable
          setMovesSkillAreaViewState={setMovesSkillAreaViewState}
          setSetMovesSkillAreaViewState={setSetMovesSkillAreaViewState}
        />
        <br />
        <div id="setMovesSkillAreaButtonDiv" style={{ paddingBottom: "20px" }}>
          <Button
            color="primary"
            type="submit"
            variant="contained"
            onClick={updateMoves}
            disabled={isLoading}
          >
            Update
          </Button>
        </div>
      </Paper>
    </Container>
  );
};
