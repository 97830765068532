import React from "react";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import { DatePicker, LocalizationProvider } from "@mui/x-date-pickers";
import { BeltInformationFormDataType } from "./SetBeltInformation";
import { TextField } from "@mui/material";

type SetBeltInformationUpdateTableProps = {
  appState: {
    beltInformation: BeltInformationType;
  };
  beltInformationFormData: BeltInformationFormDataType;
  setBeltInformationFormData: React.Dispatch<
    React.SetStateAction<BeltInformationFormDataType>
  >;
};

// table of belt fields
export const SetBeltInformationUpdateTable: React.VFC<
  SetBeltInformationUpdateTableProps
> = ({
  appState,
  beltInformationFormData,
  setBeltInformationFormData,
}: SetBeltInformationUpdateTableProps) => {
  const rows = Object.keys(appState.beltInformation).map((belt) => {
    return (
      <tr key={belt}>
        <td>{belt}</td>
        <td>
          <DatePicker
            // clearable={true}
            inputFormat="yyyy/MM/dd"
            label="Date of Belt/Stripe"
            // clearText="Not Received Yet"
            value={beltInformationFormData[belt as keyof BeltInformationType]}
            renderInput={(params) => (
              <TextField variant="standard" {...params} />
            )}
            onChange={(date) =>
              setBeltInformationFormData({
                ...beltInformationFormData,
                [belt]: date,
              })
            }
          />
        </td>
      </tr>
    );
  });
  return (
    <LocalizationProvider dateAdapter={AdapterDateFns}>
      <table style={{ textAlign: "left", marginTop: "10px" }}>
        <thead>
          <tr>
            <th>Belt</th>
            <th>Date</th>
          </tr>
        </thead>
        <tbody>{rows}</tbody>
      </table>
    </LocalizationProvider>
  );
};
