import React, { useContext } from "react";
import { Container, Paper } from "@mui/material";
import { AuthContext } from "../../App";
import { LoginSuccessMessageView } from "./LoginSuccessMessageView";
import { LoginFormView } from "./LoginFormView";

type LoginProps = {};

// component is a login screen used to authenticate the user
export const Login: React.VFC<LoginProps> = () => {
  const authenticationState = useContext(AuthContext);
  return (
    <div>
      <Container maxWidth="md">
        <Paper elevation={3} className="paper">
          {/* Login screen is only shown when user not logged in */}
          {authenticationState.isLoggedIn ? (
            <LoginSuccessMessageView />
          ) : (
            <LoginFormView />
          )}
        </Paper>
      </Container>
    </div>
  );
};
