import { AuthContext } from "App";
import axios from "axios";
import { useContext } from "react";
import { useQuery } from "react-query";

export type SessionName = { id: number; name: string; day_of_week: number };

const getSessionName = async (
  getToken: () => Promise<string | null>,
  signal?: AbortSignal
) => {
  const tokenValue = await getToken();

  return (
    await axios.request<SessionName[]>({
      url: "/api/session-names",
      method: "GET",
      headers: {
        Authorization: `Bearer ${tokenValue}`,
      },
      signal,
    })
  ).data;
};

const useSessionNames = () => {
  const authenticationState = useContext(AuthContext);
  return useQuery(["session-names"], ({ signal }) =>
    getSessionName(authenticationState.getToken, signal)
  );
};

export default useSessionNames;
