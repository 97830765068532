import { AuthContext } from "App";
import axios from "axios";
import { useContext } from "react";
import { useQuery } from "react-query";

export type Position = { id: number; name: string };

const getPositions = async (
  getToken: () => Promise<string | null>,
  signal?: AbortSignal
) => {
  const tokenValue = await getToken();

  return (
    await axios.request<Position[]>({
      url: "/api/positions",
      method: "GET",
      headers: {
        Authorization: `Bearer ${tokenValue}`,
      },
      signal,
    })
  ).data;
};

const usePositions = () => {
  const authenticationState = useContext(AuthContext);
  return useQuery(["positions"], ({ signal }) =>
    getPositions(authenticationState.getToken, signal)
  );
};

export default usePositions;
