import axios from "axios";
import { useQuery } from "react-query";
import { UserTrainingSummaryResponse } from "./useUserTrainingSummary";

export interface UserSharedInfoResponse extends UserTrainingSummaryResponse {}

const getUserSharedInfo = async (username?: string, signal?: AbortSignal) => {
  return (
    await axios.request<UserSharedInfoResponse>({
      url: `/api/public/${username}`,
      method: "GET",
      signal,
    })
  ).data;
};

const useUserSharedInfo = (username?: string) => {
  return useQuery(
    ["shared-profile", username],
    ({ signal }) => getUserSharedInfo(username, signal),
    {
      enabled: !!username,
    }
  );
};

export default useUserSharedInfo;
