import React from "react";
import Loading from "../../components/Loading";
import ErrorComponent from "../../components/ErrorComponent";
import { Paper, Typography } from "@mui/material";
import CalendarHeatmap from "react-calendar-heatmap";
import "react-calendar-heatmap/dist/styles.css";
import {
  addMonths,
  addWeeks,
  differenceInMonths,
  differenceInWeeks,
  format,
  startOfISOWeek,
} from "date-fns";
import { groups, rollup, sum } from "d3";
import { DataVisualisationLoaded } from "./DataVisualisationLoaded";
import { sortDateHelper } from "../../components/Helper";
import usePositions, { Position } from "data/queries/usePositions";
import useMoves, { Move } from "data/queries/useMoves";
import useSessionNames, { SessionName } from "data/queries/useSessionNames";
import useSessions, { Session } from "data/queries/useSessions";
import useSubmissionTrackings, {
  SubmissionTracking,
} from "data/queries/useSubmissionTrackings";

export interface AppData {
  positions: Position[];
  moves: Move[];
  sessionNames: SessionName[];
  sessions: Session[];
  submissionTracking: SubmissionTracking[];
}

export type DataVisualisationAppData = {
  positions: Position[];
  moves: Move[];
  sessionNames: SessionName[];
  sessions: Session[];
  submissionTracking: SubmissionTracking[];
};

type DataVisualisationProps = {};

// component used to display visualisation of the logged session data from Logging
// allows users to filter by date ranges and also has a comparision mode for comparing
// the same charts between against predefined ranges
export const DataVisualisation: React.VFC<DataVisualisationProps> = () => {
  const positions = usePositions();
  const moves = useMoves();
  const sessionNames = useSessionNames();
  const sessions = useSessions();
  const submissionTrackings = useSubmissionTrackings();

  if (
    positions.isLoading ||
    positions.isIdle ||
    moves.isLoading ||
    moves.isIdle ||
    sessionNames.isLoading ||
    sessionNames.isIdle ||
    sessions.isLoading ||
    sessions.isIdle ||
    submissionTrackings.isLoading ||
    submissionTrackings.isIdle
  ) {
    return <Loading />;
  }

  if (
    positions.isError ||
    moves.isError ||
    sessionNames.isError ||
    sessions.isError ||
    submissionTrackings.isError
  ) {
    return <ErrorComponent />;
  }

  const hasData = sessions.data.length > 0;
  if (sessions.data.length === 0) {
    return (
      // If there are no logged sessions then this view is displayed
      <Paper elevation={3}>
        <Typography variant="h4">No Data!</Typography>
        <Typography variant="body1">You should log some data :)</Typography>
      </Paper>
    );
  } else {
    return (
      <DataVisualisationLoaded
        appState={{
          positions: positions.data,
          moves: moves.data,
          sessionNames: sessionNames.data,
          sessions: sessions.data,
          submissionTracking: submissionTrackings.data,
        }}
      />
    );
  }
};

const generatedFixedDate = (brokenDate: Date) => {
  // generates a date object that is adjusted for timezone, due to issues
  // with date-fns format formating dates as if they're UTC and when Date objects
  // are initialise with a data default having a timezone
  return new Date(
    brokenDate.valueOf() + brokenDate.getTimezoneOffset() * 60 * 1000
  );
};

export const sessionTypePieDataGenerator = (
  appState: DataVisualisationAppData
) => {
  // generates and formats data for session type (gi, no gi, unspecified) processing
  let sessionTypePieData = appState.sessions.reduce((acc, cur) => {
    let newAcc = [...acc];
    if (cur.type != "Unspecified") {
      // ignore unspecified entries
      const indexOfType = newAcc.findIndex(
        (element) => element.name === cur.type
      );

      if (indexOfType !== -1) {
        newAcc[indexOfType].value += 1;
      } else {
        newAcc.push({ name: cur.type, value: 0 });
      }
    }
    return newAcc;
  }, [] as { name: string; value: number }[]);
  // calculate percentages
  const totalValues = sessionTypePieData.reduce((acc, cur) => {
    return acc + cur.value;
  }, 0);
  sessionTypePieData = sessionTypePieData.map((type) => {
    let percent = (type.value / totalValues) * 100;
    return { ...type, percentage: `${percent.toFixed(1)}%` };
  });

  return sessionTypePieData;
};

export const cumulativeMatHoursGenerator = (
  appState: DataVisualisationAppData
) => {
  // generator and formatter of data for cumulative mat hours
  return appState.sessions.sort(sortDateHelper).reduce((acc, cur) => {
    let newAcc = [...acc];
    if (acc.length === 0) {
      // array is empty
      newAcc.push({
        name: new Date(cur.date).valueOf(),
        value: cur.duration,
      });
    } else {
      newAcc.push({
        name: new Date(cur.date).valueOf(),
        value: newAcc[newAcc.length - 1].value + cur.duration,
      });
    }
    return newAcc;
  }, [] as { name: number; value: number }[]);
};

export const monthlyAttendanceDataGenerator = (
  appState: DataVisualisationAppData
) => {
  // generator and formatter of data for monthly attendance
  const monthlyAttendanceDataMap = rollup(
    appState.sessions.map((session) => {
      return {
        ...session,
        month: format(generatedFixedDate(new Date(session.date)), "yyyy-MM"),
      };
    }),
    (v) => sum(v, (d) => d.duration),
    (d) => d.month
  );

  let monthlyAttendanceData = Array.from(monthlyAttendanceDataMap).map(
    (value) => {
      return { name: value[0], value: value[1] };
    }
  );

  //add in empty months
  monthlyAttendanceData = monthlyAttendanceData
    .sort((a, b) => {
      const aYearMonth = a.name.split("-");
      const bYearMonth = b.name.split("-");
      const aDate = new Date(
        parseInt(aYearMonth[0]),
        parseInt(aYearMonth[1]) - 1, // month is 0-indexed
        1
      );
      const bDate = new Date(
        parseInt(bYearMonth[0]),
        parseInt(bYearMonth[1]) - 1, // month is 0-indexed
        1
      );
      if (aDate.getTime() === bDate.getTime()) {
        return 0;
      } else if (aDate > bDate) {
        return 1;
      } else {
        return -1;
      }
    })
    .reduce((acc, cur) => {
      let newAcc = [...acc];
      if (acc.length === 0) {
        // array is empty
        newAcc.push(cur);
      } else {
        const curYearMonth = cur.name.split("-");
        const curDate = new Date(
          parseInt(curYearMonth[0]),
          parseInt(curYearMonth[1]) - 1, // month is 0-indexed
          1
        );
        const preYearMonth = acc[acc.length - 1].name.split("-");
        const preDate = new Date(
          parseInt(preYearMonth[0]),
          parseInt(preYearMonth[1]) - 1, // month is 0-indexed
          1
        );

        const diffInMonths = differenceInMonths(curDate, preDate);
        if (diffInMonths > 1) {
          //need to fill in blank months
          for (let i = 1; i < diffInMonths; i++) {
            newAcc.push({
              name: format(
                generatedFixedDate(addMonths(preDate, i)),
                "yyyy-MM"
              ),
              value: 0,
            });
          }
          newAcc.push(cur);
        } else if (diffInMonths === 1) {
          // no need to fill in blank months
          newAcc.push(cur);
        } else {
          // error, sorting has not occurred or other issue
          throw new Error("Error with monthly attendance data generation");
        }
      }
      return newAcc;
    }, [] as { name: string; value: number }[]);

  return monthlyAttendanceData;
};

export const dailyCalendarHeatmapsGenerator = (
  appState: DataVisualisationAppData
) => {
  // generates dailyCalendarHeatmaps

  // formats daily training data
  const dailyAttendanceData = groups(
    appState.sessions,
    (d) => d.date.split("-")[0],
    (d) => d.date
  ).map((yearArray) => {
    return {
      year: yearArray[0],
      data: yearArray[1].map((entry) => {
        return {
          date: entry[0].split("T")[0],
          count: entry[1].length,
          sessions: entry[1].map((session) => {
            return {
              sessionName: appState.sessionNames.find(
                (sessionName) => sessionName.id === session.session
              )?.name,
              type: session.type,
              mode: session.mode,
              duration: session.duration,
            };
          }),
        };
      }),
    };
  });

  // add missing years if needed
  const years = new Set(dailyAttendanceData.map((value) => value.year));
  const largestYear = Math.max(
    ...Array.from(years).map((year) => parseInt(year))
  );
  years.forEach((year) => {
    const yearInt = parseInt(year);
    if (yearInt !== largestYear) {
      const nextYear = yearInt + 1;
      if (!years.has(nextYear.toString())) {
        dailyAttendanceData.push({
          year: nextYear.toString(),
          data: [],
        });
      }
    }
  });
  dailyAttendanceData.sort((a, b) => {
    return parseInt(a.year) - parseInt(b.year);
  });

  const dailyCalendarHeatmaps = dailyAttendanceData.map((value) => {
    return (
      <div
        style={{
          display: "flex",
          flexDirection: "column",
        }}
        key={value.year}
      >
        <Typography variant="h5">{value.year}</Typography>
        <CalendarHeatmap
          startDate={`${parseInt(value.year) - 1}-12-31`} // using day before start of year due to code not accounting for Date and UTC issues, replace calendar vis with a better more maintained on or make own
          endDate={`${parseInt(value.year)}-12-31`}
          values={value.data}
          showWeekdayLabels={true}
          classForValue={(value) => {
            if (!value) {
              return "color-empty";
            }
            return `color-github-${value.count}`;
          }}
          tooltipDataAttrs={(value: any) => {
            if (value.date !== null) {
              let toolTipString = `Date: ${
                value.date.split("T")[0]
              }, Sessions: ${value.count} <div style="text-align:left"><ul>`;
              value.sessions.map(
                (session: {
                  sessionName: string;
                  type: string;
                  mode: string;
                  duration: number;
                }) => {
                  toolTipString += `<li> ${session.sessionName}, ${session.type}, ${session.mode}, ${session.duration} hrs </li>`;
                }
              );
              toolTipString += `</ul></div>`;

              return {
                "data-tip": toolTipString,
              };
            }
          }}
        />
      </div>
    );
  });

  return dailyCalendarHeatmaps;
};

export const weeklyAttendanceDataGenerator = (
  appState: DataVisualisationAppData
) => {
  // generator and formatter data for weekly attendance
  const weeklyAttendanceDataMap = rollup(
    appState.sessions.map((session) => {
      return {
        ...session,
        week: format(
          startOfISOWeek(generatedFixedDate(new Date(session.date))),
          "yyyy-MM-dd"
        ),
      };
    }),
    (v) => sum(v, (d) => d.duration),
    (d) => d.week
  );

  let weeklyAttendanceData = Array.from(weeklyAttendanceDataMap).map(
    (value) => {
      return { name: value[0], value: value[1] };
    }
  );

  //add in empty weeks
  weeklyAttendanceData = weeklyAttendanceData
    .sort((a, b) => {
      const aDate = generatedFixedDate(new Date(a.name));
      const bDate = generatedFixedDate(new Date(b.name));
      if (aDate.getTime() === bDate.getTime()) {
        return 0;
      } else if (aDate > bDate) {
        return 1;
      } else {
        return -1;
      }
    })
    .reduce((acc, cur) => {
      let newAcc = [...acc];
      if (acc.length === 0) {
        // array is empty
        newAcc.push(cur);
      } else {
        const curDate = generatedFixedDate(new Date(cur.name));
        const preDate = generatedFixedDate(new Date(acc[acc.length - 1].name));

        const diffInWeeks = differenceInWeeks(curDate, preDate);
        if (diffInWeeks > 1) {
          //need to fill in blank months
          for (let i = 1; i < diffInWeeks; i++) {
            newAcc.push({
              name: format(addWeeks(preDate, i), "yyyy-MM-dd"),
              value: 0,
            });
          }
          newAcc.push(cur);
        } else if (diffInWeeks === 1) {
          // no need to fill in blank months
          newAcc.push(cur);
        } else {
          // error, sorting has not occurred or other issue
          throw new Error("Error with weekly attendance data generation");
        }
      }
      return newAcc;
    }, [] as { name: string; value: number }[])
    .map((value) => {
      return {
        name: format(generatedFixedDate(new Date(value.name)), "RRRR-II"),
        value: value.value,
      };
    });

  return weeklyAttendanceData;
};

export const positionsFocusInSessionsDataGenerator = (
  appState: DataVisualisationAppData
) => {
  let arrayOfSessions: {
    sessionID: number;
    numberOfPositions: number;
    positionalWorth: number;
    positions: string[];
  }[] = [];

  appState.sessions.forEach((session) => {
    arrayOfSessions.push({
      sessionID: session.id,
      numberOfPositions: session.positions.length,
      positionalWorth: 1 / session.positions.length,
      positions: session.positions.map((position) => position.name),
    });
  });

  let positionsFocusInSessionsData = appState.positions.map((position) => ({
    name: position.name,
    value: 0,
  }));

  arrayOfSessions.forEach((session) => {
    session.positions.forEach((position) => {
      const index = positionsFocusInSessionsData.findIndex(
        (element) => element.name === position
      );
      if (index !== -1) {
        positionsFocusInSessionsData[index].value += session.positionalWorth;
      } else {
        throw new Error("Error with positions for positionsFocus");
      }
    });
  });

  return positionsFocusInSessionsData;
};

export const movesFocusInSessionsDataGenerator = (
  appState: DataVisualisationAppData
) => {
  let arrayOfSessions: {
    sessionID: number;
    numberOfMoves: number;
    movalWorth: number;
    moves: string[];
  }[] = [];

  appState.sessions.forEach((session) => {
    arrayOfSessions.push({
      sessionID: session.id,
      numberOfMoves: session.moves.length,
      movalWorth: 1 / session.moves.length,
      moves: session.moves.map((move) => move.name),
    });
  });

  let movesFocusInSessionsData: {
    name: string;
    value: number;
  }[] = appState.moves.map((move) => ({
    name: move.name,
    value: 0,
  }));

  arrayOfSessions.forEach((session) => {
    session.moves.forEach((move) => {
      const index = movesFocusInSessionsData.findIndex(
        (element) => element.name === move
      );
      if (index !== -1) {
        movesFocusInSessionsData[index].value += session.movalWorth;
      } else {
        throw new Error("Error with moves for movesFocus");
      }
    });
  });
  return movesFocusInSessionsData;
};

export const movesSkillAreaFocusDataGenerator = (
  appState: DataVisualisationAppData
) => {
  const movesFocusInSessionsData = movesFocusInSessionsDataGenerator(appState);

  let movesSkillAreaFocusData = [] as { name: string; value: number }[];

  movesFocusInSessionsData.forEach((move) => {
    const skillAreaForMove = appState.moves.find(
      (element) => element.name === move.name
    );
    if (skillAreaForMove === undefined) {
      throw new Error("There was an error: Moves Skill Area");
    }

    const skill = movesSkillAreaFocusData.find(
      (element) => element.name === skillAreaForMove!.skillarea
    );
    if (skill !== undefined) {
      skill.value += move.value;
    } else {
      movesSkillAreaFocusData.push({
        name: skillAreaForMove!.skillarea,
        value: move.value,
      });
    }
  });

  return movesSkillAreaFocusData;
};

export const submissionTrackingDataGenerator = (
  appState: DataVisualisationAppData
) => {
  const submissionTrackingRollup = rollup(
    appState.submissionTracking,
    (v) => sum(v, (d) => d.number_of_executions),
    (d) => d.name
  );
  const submissionTrackingData = Array.from(submissionTrackingRollup).map(
    (value) => {
      return { name: value[0], value: value[1] };
    }
  );
  return submissionTrackingData;
};
