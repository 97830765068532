import { Container, Paper, Typography } from "@mui/material";
import React, { useContext } from "react";
import { AuthContext } from "../../App";
import "./HomeView.css";
import { Link } from "react-router-dom";
import { CreatedByMessage } from "./CreatedByMessage";
import { TrainingSummary } from "./TrainingSummary";

// component used to provide a welcome message for the user
// if they are logged in a summary of their data is displayed
// as well as their belt information if they have entered it
type HomeViewProps = {};
export const HomeView: React.VFC<HomeViewProps> = () => {
  const authenticationState = useContext(AuthContext);

  if (authenticationState.isLoggedIn) {
    return (
      <Container maxWidth="md">
        <Paper elevation={3} className="paper">
          <Typography variant="h4">
            Hello {authenticationState.username},
          </Typography>
          <Typography variant="h5">Welcome to Grapple-Training-Log!</Typography>
          <br />
          <Typography variant="body1">
            Basic functionality is available but it is still very much
            under construction. The database may get wiped from time to time
            without notice so do not rely the site to store your data. A copy of
            your information may be downloaded from the{" "}
            <Link to="/data-view">Data View</Link> table and reimported in{" "}
            <Link to="/logging">Logging</Link>.
          </Typography>
          <br />
          <TrainingSummary />
          <br />
          <CreatedByMessage />
        </Paper>
      </Container>
    );
  } else {
    return (
      <Container maxWidth="md">
        <Paper elevation={3} className="paper">
          <Typography variant="h4">Welcome to Grapple-Training-Log</Typography>
          <br />
          <Typography variant="body1">
            This web app allows users to log their grappling training sessions
            (Brazilian Jiu Jitsu for now, Judo and Wrestling to be added later).
            Details that can be logged include:
          </Typography>
          <ul>
            <li>Training notes</li>
            <li>Injuries notes</li>
            <li>Duration of sessions</li>
            <li>Amount of sparring/rolling undertaken</li>
            <li>Focus topics of the session</li>
          </ul>
          <Typography variant="body1">
            This data is then able to be visualised and analysed to gain
            insights on the users training.
          </Typography>

          <Typography variant="body1">
            Basic functionality is available but it is still very much under
            construction. The database may get wiped from time to time without
            notice so do not rely the site to store your data. A copy of your
            information may be downloaded from the{" "}
            <Link to="/data-view">Data View</Link> table and reimported in{" "}
            <Link to="/logging">Logging</Link>.
          </Typography>
          <br />
          <CreatedByMessage />
        </Paper>
      </Container>
    );
  }
};
