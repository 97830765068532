import HomeIcon from "@mui/icons-material/Home";
import StorageIcon from "@mui/icons-material/Storage";
import TableChartIcon from "@mui/icons-material/TableChart";
import BarChartIcon from "@mui/icons-material/BarChart";
import OpenInBrowserIcon from "@mui/icons-material/OpenInBrowser";
import ExitToAppIcon from "@mui/icons-material/ExitToApp";
import SportsMmaIcon from "@mui/icons-material/SportsMma";
import SettingsInputComponentIcon from "@mui/icons-material/SettingsInputComponent";
import AssignmentIcon from "@mui/icons-material/Assignment";
import React from "react";

// define extra information for views (components) for routing
// used to define view name in navigation
export const viewStates: ViewState[] = [
  {
    key: "home",
    displayName: "Home",
    routeLocation: "/",
    inSideMenu: true,
    inUserProfile: false,
    icon: <HomeIcon />,
    requiresLogin: false,
  },
  {
    key: "logging",
    displayName: "Logging",
    routeLocation: "/logging",
    inSideMenu: true,
    inUserProfile: false,
    icon: <StorageIcon />,
    requiresLogin: true,
  },
  {
    key: "data-view",
    displayName: "Data View",
    routeLocation: "/data-view",
    inSideMenu: true,
    inUserProfile: false,
    icon: <TableChartIcon />,
    requiresLogin: true,
  },
  {
    key: "submission-tracking",
    displayName: "Submission Tracking",
    routeLocation: "/submission-tracking",
    inSideMenu: true,
    inUserProfile: false,
    icon: <SportsMmaIcon />,
    requiresLogin: true,
  },
  {
    key: "submission-data-view",
    displayName: "Submission Data View",
    routeLocation: "/submission-data-view",
    inSideMenu: true,
    inUserProfile: false,
    icon: <TableChartIcon />,
    requiresLogin: true,
  },
  {
    key: "data-vis",
    displayName: "Data Visualisation",
    routeLocation: "/data-vis",
    inSideMenu: true,
    inUserProfile: false,
    icon: <BarChartIcon />,
    requiresLogin: true,
  },
  {
    key: "set-skill-area",
    displayName: "Set Moves Skill Area",
    routeLocation: "/set-skill-area",
    inSideMenu: false,
    inUserProfile: true,
    icon: <SettingsInputComponentIcon />,
    requiresLogin: true,
  },
  {
    key: "set-belt-Information",
    displayName: "Set Belt Information",
    routeLocation: "/set-belt-information",
    inSideMenu: false,
    inUserProfile: true,
    icon: <AssignmentIcon />,
    requiresLogin: true,
  },
  {
    key: "login",
    displayName: "Login",
    routeLocation: "/login",
    inSideMenu: false,
    inUserProfile: false,
    icon: <OpenInBrowserIcon />,
    requiresLogin: false,
  },
  {
    key: "logout",
    displayName: "Logout",
    routeLocation: "/logout",
    inSideMenu: false,
    inUserProfile: false,
    icon: <ExitToAppIcon />,
    requiresLogin: true,
  },
  {
    key: "user-profile",
    displayName: "User Profile",
    routeLocation: "/user-profile",
    inSideMenu: false,
    inUserProfile: false,
    icon: null,
    requiresLogin: true,
  },
  {
    key: "shared",
    displayName: "Shared",
    routeLocation: "/shared/:username",
    inSideMenu: false,
    inUserProfile: false,
    icon: null,
    requiresLogin: false,
  },
];
