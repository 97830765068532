import { ListItem, ListItemIcon, ListItemText } from "@mui/material";
import { AuthContext } from "App";
import React, { useContext } from "react";
import { Link, LinkProps } from "react-router-dom";

type ListItemLinkProps = {
  icon?: React.ReactElement;
  text: string;
  to: string;
  requiresLogin: boolean;
};

// component used as ListItem that also is a react-router route link
export const ListItemLink: React.FC<ListItemLinkProps> = ({
  icon,
  text,
  to,
  requiresLogin,
}: ListItemLinkProps) => {
  const authenticationState = useContext(AuthContext);
  const CustomLink = React.useMemo(
    () =>
      React.forwardRef<any, Omit<LinkProps, "to">>(function CustomLink(
        props,
        ref
      ) {
        return <Link ref={ref} to={to} {...props} />;
      }),
    [to]
  );

  if (requiresLogin && !authenticationState.isLoggedIn) {
    return null;
  }

  return (
    <ListItem button component={CustomLink}>
      {icon ? <ListItemIcon>{icon}</ListItemIcon> : null}
      <ListItemText primary={text} />
    </ListItem>
  );
};
