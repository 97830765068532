import {
  Card,
  CardContent,
  Slider,
  Typography,
  useMediaQuery,
} from "@mui/material";
import { sum } from "d3";
import React, { useState } from "react";
import { PieChart, Pie, Tooltip, Cell } from "recharts";
import {
  categoricalColours,
  renderCustomizedLabel,
  renderCustomizedLabelLine,
} from "../../../components/Helper";

type PieChartSubmissionTrackingVisProps = {
  size: { width: number; height: number; radius?: number };
  data: { name: string; value: number }[];
  visible?: boolean;
  movesColourAssignment?: { name: string; colour: string }[] | undefined;
};

// Pie chart component to visualise the submissions tracked
export const PieChartSubmissionTrackingVis: React.VFC<
  PieChartSubmissionTrackingVisProps
> = ({
  size,
  data,
  visible = true,
  movesColourAssignment = undefined,
}: PieChartSubmissionTrackingVisProps) => {
  type VisualisationStateType = {
    pieSegments: number;
  };

  const initialVisualisationState: VisualisationStateType = {
    pieSegments: 10,
  };

  // state is the number of pie segments to display
  const [visualisationState, setVisualisationState] = useState(
    initialVisualisationState
  );

  // sort values
  let filteredData = data.sort((a, b) => {
    return b.value - a.value;
  });

  // summarise values under top "segments"
  if (data.length > visualisationState.pieSegments) {
    let otherValuesArray = filteredData.slice(
      visualisationState.pieSegments - 1
    );
    filteredData = filteredData.slice(0, visualisationState.pieSegments - 1);
    filteredData.push({
      name: "others",
      value: sum(otherValuesArray, (d) => d.value),
    });
  }

  // calculate percentages
  const totalValues = filteredData.reduce((acc, cur) => {
    return acc + cur.value;
  }, 0);

  filteredData = filteredData.map((type) => {
    let percent = (type.value / totalValues) * 100;
    return { ...type, percentage: `${percent.toFixed(1)}%` };
  });

  // colours for pi chart
  const coloursForVis = categoricalColours(filteredData.length);

  const matches = useMediaQuery("(max-width:414px)");

  return (
    <Card
      style={{
        width: `${size.width}px`,
        marginBottom: "1%",
        overflow: "visible",
        display: visible ? "initial" : "none",
      }}
    >
      <CardContent
        style={{
          paddingTop: matches ? "0px" : "16px",
          paddingBottom: matches ? "0px" : "24px",
        }}
      >
        <Typography color="textSecondary" gutterBottom>
          Submission Tracker
        </Typography>
        <PieChart width={size.width - 32} height={size.height}>
          <Pie
            dataKey="value"
            data={filteredData}
            cx={(size.width - 32) / 2}
            cy={size.height / 2}
            outerRadius={size.radius}
            label={renderCustomizedLabel}
            labelLine={renderCustomizedLabelLine}
            isAnimationActive={false}
          >
            {filteredData.map((entry, index) => {
              return (
                <Cell
                  key={`cell-${index}`}
                  fill={
                    movesColourAssignment === undefined
                      ? coloursForVis[index]
                      : movesColourAssignment.find(
                          (element) => element.name === entry.name
                        )?.colour
                  }
                />
              );
            })}
          </Pie>
          <Tooltip
            allowEscapeViewBox={{ x: true, y: true }}
            formatter={(
              value: any,
              name: any,
              item: { payload?: { value: number; percentage: string } }
            ) => {
              return `${
                item.payload?.value ? item.payload.value.toFixed(1) : ""
              } ${
                item.payload?.percentage
                  ? "(" + item.payload.percentage + ")"
                  : ""
              }`;
            }}
          />
        </PieChart>
        <Slider
          value={visualisationState.pieSegments}
          onChange={(event, value) =>
            setVisualisationState({
              ...visualisationState,
              pieSegments: value as number,
            })
          }
          defaultValue={10}
          valueLabelDisplay="auto"
          step={1}
          min={1}
          max={data.length}
        />
      </CardContent>
    </Card>
  );
};
