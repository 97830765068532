import { Card, CardHeader, CardContent } from "@mui/material";
import React from "react";
import "./DataCard.css";

type SharedDataCardProps = {
  data: {
    trainingTime: string;
    totalMatTime: string;
    totalTeachingTime: string;
    totalRollingTime: string;
    averageMatTimePerMonth: string;
    currentInstantaneousMatTimePerMonth: string;
    totalInjuryIncidents: string;
  };
};

export const SharedDataCard: React.VFC<SharedDataCardProps> = ({
  data: {
    trainingTime,
    totalMatTime,
    totalTeachingTime,
    totalRollingTime,
    averageMatTimePerMonth,
    currentInstantaneousMatTimePerMonth,
    totalInjuryIncidents,
  },
}: SharedDataCardProps) => {
  return (
    <Card
      style={{
        marginBottom: "5px",
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        backgroundColor: "#e8eaf6",
      }}
    >
      <CardHeader
        title="Summary of Recorded Data"
        style={{ paddingBottom: "0px" }}
      />
      <CardContent
        style={{
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
        }}
      >
        <table>
          <tbody>
            <tr>
              <td className="label">Training Time:</td>
              <td className="data">{trainingTime}</td>
            </tr>
            <tr>
              <td className="label">Total Mat Time:</td>
              <td className="data">{totalMatTime}</td>
            </tr>
            <tr>
              <td className="label">Total Teaching:</td>
              <td className="data">{totalTeachingTime}</td>
            </tr>
            <tr>
              <td className="label">Total Rolling Time:</td>
              <td className="data">{totalRollingTime}</td>
            </tr>
            <tr>
              <td className="label">Average Mat Time per Month:</td>
              <td className="data">{averageMatTimePerMonth}</td>
            </tr>
            <tr>
              <td className="label">
                Current Instantaneous Mat Time (last 30 days):
              </td>
              <td className="data">{currentInstantaneousMatTimePerMonth}</td>
            </tr>
            <tr>
              <td className="label">Total Injury Incidents:</td>
              <td className="data">{totalInjuryIncidents}</td>
            </tr>
          </tbody>
        </table>
      </CardContent>
    </Card>
  );
};
