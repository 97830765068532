import React, { useState } from "react";
import "./Logging.css";
import Loading from "../../components/Loading";
import ErrorComponent from "../../components/ErrorComponent";
import useInsertData from "../../data/mutations/useInsertData";
import { dateToISOLikeButLocal } from "../../components/Helper";
import { LoggingImportView } from "./LoggingImportView";
import { LoggingLoaded } from "./LoggingLoaded";
import usePositions, { Position } from "data/queries/usePositions";
import useMoves, { Move } from "data/queries/useMoves";
import useSessionNames, { SessionName } from "data/queries/useSessionNames";

export type LoggingFormValuesType = {
  date: Date;
  sessionName: string;
  trainingMode: string;
  trainingType: string;
  duration: number;
  positions: string[];
  moves: string[];
  notes: string;
  numberOfRollingRounds: number;
  rollingRoundDuration: number;
  situationalSparringPosition: string | null;
  numberOfSituationalRounds: number;
  situationalRoundDuration: number;
  injuryNote: string;
};

export type SanitisedLoggingFormDataType = {
  date: string;
  sessionName: string;
  trainingMode: string;
  trainingType: string;
  duration: number;
  positions: string[];
  moves: string[];
  notes: string;
  numberOfRollingRounds: number;
  rollingRoundDuration: number;
  situationalSparringPosition: string | null;
  numberOfSituationalRounds: number;
  situationalRoundDuration: number;
  injuryNote: string;
};

export interface LoggingAppState {
  state: "loaded" | "import";
}

export interface LoggingAppData {
  positions: Position[];
  moves: Move[];
  sessionNames: SessionName[];
}

type LoggingProps = {};

// component use to log training session information
export const Logging: React.VFC<LoggingProps> = () => {
  const initialFormValues: LoggingFormValuesType = {
    date: new Date(),
    sessionName: "",
    trainingMode: "Learning",
    trainingType: "Gi",
    duration: 1.5,
    positions: [] as string[],
    moves: [] as string[],
    notes: "",
    numberOfRollingRounds: 0,
    rollingRoundDuration: 0,
    situationalSparringPosition: null, //this might need to be and input of inputs
    numberOfSituationalRounds: 0,
    situationalRoundDuration: 0,
    injuryNote: "",
  };

  const [formValues, setFormValues] = useState(initialFormValues);

  // function call for inserting session information into the database
  // also currently used for importing data from the CSV
  const { apiCall, status } = useInsertData<SanitisedLoggingFormDataType>(
    "api/sessions",
    {
      method: "POST",
      onSuccessCallback(data, variables, context) {
        setFormValues(initialFormValues);
        moves.refetch();
        positions.refetch();
        sessionNames.refetch();
      },
    }
  );

  // function called when submit button is clicked and the entered data is valid
  const onSubmit = (event: React.FormEvent) => {
    // format the form data
    const sanitisedFormData = {
      ...formValues,
      sessionName: formValues.sessionName.trim(),
      situationalSparringPosition:
        formValues.situationalSparringPosition === null
          ? null
          : formValues.situationalSparringPosition.trim(),
      positions: formValues.positions.map((value) => value.trim()),
      moves: formValues.moves.map((value) => value.trim()),
      notes: formValues.notes.trim(),
      injuryNote: formValues.injuryNote.trim(),
      date: dateToISOLikeButLocal(formValues.date).split("T", 1)[0],
    };

    apiCall(sanitisedFormData);
    event.preventDefault(); // stop normal form action from occurring
  };

  const initialAppState: LoggingAppState = {
    state: "loaded",
  };
  const [appState, setAppState] = useState<LoggingAppState>(initialAppState);

  const moves = useMoves();
  const positions = usePositions();
  const sessionNames = useSessionNames();

  if (
    moves.isLoading ||
    moves.isIdle ||
    positions.isLoading ||
    positions.isIdle ||
    sessionNames.isLoading ||
    sessionNames.isIdle
  ) {
    return <Loading />;
  }

  if (moves.isError || positions.isError || sessionNames.isError) {
    return <ErrorComponent />;
  }

  if (appState.state === "loaded") {
    return (
      <LoggingLoaded
        appData={{
          positions: positions.data,
          moves: moves.data,
          sessionNames: sessionNames.data,
        }}
        setAppState={setAppState}
        formValues={formValues}
        setFormValues={setFormValues}
        onSubmit={onSubmit}
        isSubmitting={status === "loading"}
      />
    );
  } else if (appState.state === "import") {
    return (
      <LoggingImportView
        setAppState={setAppState}
        apiInsertSessionCall={apiCall}
        isSubmitting={status === "loading"}
      />
    );
  } else {
    return <Loading />;
  }
};
