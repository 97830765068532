import React from "react";
import Loading from "components/Loading";
import ErrorComponent from "components/ErrorComponent";
import useUserTrainingSummary from "data/queries/useUserTrainingSummary";
import { SharedBeltTableTable } from "./BeltTable";
import { SharedDataCard } from "./DataCard";

type TrainingSummaryProps = {};
export const TrainingSummary: React.VFC<TrainingSummaryProps> = () => {
  const trainingSummaryData = useUserTrainingSummary();
  if (trainingSummaryData.isLoading || trainingSummaryData.isIdle) {
    return <Loading />;
  }
  if (trainingSummaryData.isError) {
    return <ErrorComponent />;
  }
  return (
    <>
      <SharedDataCard data={trainingSummaryData.data} />
      <SharedBeltTableTable data={trainingSummaryData.data.beltSummary} />
    </>
  );
};
