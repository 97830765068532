import useMe from "data/queries/useMe";
import React from "react";
import Loading from "components/Loading";
import ErrorComponent from "components/ErrorComponent";
import { SetBeltInformationLoaded } from "./SetBeltInfomationLoaded";

export type BeltInformationFormDataType = {
  bjjwhite0: Date | null;
  bjjwhite1: Date | null;
  bjjwhite2: Date | null;
  bjjwhite3: Date | null;
  bjjwhite4: Date | null;
  bjjblue0: Date | null;
  bjjblue1: Date | null;
  bjjblue2: Date | null;
  bjjblue3: Date | null;
  bjjblue4: Date | null;
  bjjpurple0: Date | null;
  bjjpurple1: Date | null;
  bjjpurple2: Date | null;
  bjjpurple3: Date | null;
  bjjpurple4: Date | null;
  bjjbrown0: Date | null;
  bjjbrown1: Date | null;
  bjjbrown2: Date | null;
  bjjbrown3: Date | null;
  bjjbrown4: Date | null;
  bjjblack0: Date | null;
  bjjblack1: Date | null;
  bjjblack2: Date | null;
  bjjblack3: Date | null;
  bjjblack4: Date | null;
};

// form used by the user to set their belt information
// load data
type SetBeltInformationProps = {};
export const SetBeltInformation: React.VFC<SetBeltInformationProps> = () => {
  const me = useMe();
  if (me.isLoading || me.isIdle) {
    return <Loading />;
  }
  if (me.isError) {
    return <ErrorComponent />;
  }

  const {
    userid,
    username,
    created_at,
    updated_at,
    last_login,
    share_public_data,
    ...beltInformation
  } = me.data;
  
  const appState = {
    beltInformation,
  };

  return <SetBeltInformationLoaded appState={appState} />;
};
