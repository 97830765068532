import { AuthContext } from "App";
import axios from "axios";
import { TotalBeltSummaryType } from "pages/HomeView/TrainingSummary/BeltTable";
import { useContext } from "react";
import { useQuery } from "react-query";

export interface UserTrainingSummaryResponse {
  userid: number;
  username: string;
  trainingTime: string;
  totalMatTime: string;
  totalTeachingTime: string;
  totalRollingTime: string;
  averageMatTimePerMonth: string;
  currentInstantaneousMatTimePerMonth: string;
  totalInjuryIncidents: string;
  beltSummary: TotalBeltSummaryType;
}

const getUserTrainingSummary = async (
  getToken: () => Promise<string | null>,
  signal?: AbortSignal
) => {
  const tokenValue = await getToken();

  return (
    await axios.request<UserTrainingSummaryResponse>({
      url: "/api/users/profile/training-summary",
      method: "GET",
      headers: {
        Authorization: `Bearer ${tokenValue}`,
      },
      signal,
    })
  ).data;
};

const useUserTrainingSummary = () => {
  const authenticationState = useContext(AuthContext);
  return useQuery(["/api/users/profile/training-summary"], ({ signal }) =>
    getUserTrainingSummary(authenticationState.getToken, signal)
  );
};

export default useUserTrainingSummary;
