import { AuthContext } from "App";
import axios from "axios";
import { useContext } from "react";
import { useQuery } from "react-query";

export type Me = BeltInformationType & {
  userid: number;
  username: string;
  created_at: string;
  updated_at: string;
  last_login: string;
  share_public_data: boolean;
};

const getMe = async (
  getToken: () => Promise<string | null>,
  signal?: AbortSignal
) => {
  const tokenValue = await getToken();

  return (
    await axios.request<Me>({
      url: "/api/users/profile",
      method: "GET",
      headers: {
        Authorization: `Bearer ${tokenValue}`,
      },
      signal,
    })
  ).data;
};

const useMe = () => {
  const authenticationState = useContext(AuthContext);
  return useQuery(["me"], ({ signal }) =>
    getMe(authenticationState.getToken, signal)
  );
};

export default useMe;
