import React from "react";
import { Typography } from "@mui/material";

// Not called `Error` to prevent conflict with the Error object
const ErrorComponent = () => {
  return (
    <div
      style={{
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
      }}
    >
      <br />
      <Typography variant="h2">
        An error has occurred whilst loading data, please try refreshing the
        page.
      </Typography>
    </div>
  );
};

export default ErrorComponent;
