import { Card, CardContent, Typography, useMediaQuery } from "@mui/material";
import React from "react";
import { PieChart, Pie, Tooltip, Cell } from "recharts";
import {
  categoricalColours,
  renderCustomizedLabel,
  renderCustomizedLabelLine,
} from "../../../components/Helper";

type PieChartMovesSkillAreaFocusVisProps = {
  size: { width: number; height: number; radius?: number };
  data: { name: string; value: number }[];
  visible?: boolean;
  skillsColourAssignment?: { name: string; colour: string }[] | undefined;
};

// Pie chart component to visualise skill areas drilled in sessions
export const PieChartMovesSkillAreaFocusVis: React.VFC<
  PieChartMovesSkillAreaFocusVisProps
> = ({
  size,
  data,
  visible = true,
  skillsColourAssignment = undefined,
}: PieChartMovesSkillAreaFocusVisProps) => {
  // sort values
  let filteredData = data.sort((a, b) => {
    return b.value - a.value;
  });

  // calculate percentages
  const totalValues = filteredData.reduce((acc, cur) => {
    return acc + cur.value;
  }, 0);

  filteredData = filteredData.map((type) => {
    let percent = (type.value / totalValues) * 100;
    return { ...type, percentage: `${percent.toFixed(1)}%` };
  });

  // colours for pi chart
  const coloursForVis = categoricalColours(filteredData.length);

  const matches = useMediaQuery("(max-width:414px)");

  return (
    <Card
      style={{
        width: `${size.width}px`,
        marginBottom: "1%",
        overflow: "visible",
        display: visible ? "initial" : "none",
      }}
    >
      <CardContent
        style={{
          paddingTop: matches ? "0px" : "16px",
          paddingBottom: matches ? "0px" : "24px",
        }}
      >
        <Typography color="textSecondary" gutterBottom>
          Skills Focus in Sessions
        </Typography>
        <PieChart width={size.width - 32} height={size.height}>
          <Pie
            dataKey="value"
            data={filteredData}
            cx={(size.width - 32) / 2}
            cy={size.height / 2}
            outerRadius={size.radius}
            label={renderCustomizedLabel}
            labelLine={renderCustomizedLabelLine}
            isAnimationActive={false}
          >
            {filteredData.map((entry, index) => {
              return (
                <Cell
                  key={`cell-${index}`}
                  fill={
                    skillsColourAssignment === undefined
                      ? coloursForVis[index]
                      : skillsColourAssignment.find((element) => {
                          return element.name === entry.name;
                        })?.colour
                  }
                />
              );
            })}
          </Pie>
          <Tooltip
            allowEscapeViewBox={{ x: true, y: true }}
            formatter={(
              value: any,
              name: any,
              item: { payload?: { value: number; percentage: string } }
            ) => {
              return `${
                item.payload?.value ? item.payload.value.toFixed(1) : ""
              } ${
                item.payload?.percentage
                  ? "(" + item.payload.percentage + ")"
                  : ""
              }`;
            }}
          />
        </PieChart>
      </CardContent>
    </Card>
  );
};
