import useMoves from "data/queries/useMoves";
import React from "react";
import Loading from "components/Loading";
import ErrorComponent from "components/ErrorComponent";
import { SetMovesSkillAreaLoaded } from "./SetMovesSkillAreaLoaded";

export type SetMovesSkillAreaViewStateType = {
  skillarea: string | null;
  id: number;
  name: string;
}[];

type SetMovesSkillAreaProps = {};

// component used by the user to assign/update skill areas to moves they have already logged
export const SetMovesSkillArea: React.VFC<SetMovesSkillAreaProps> = () => {
  const moves = useMoves();
  if (moves.isLoading || moves.isIdle) {
    return <Loading />;
  }
  if (moves.isError) {
    return <ErrorComponent />;
  }

  const appState = {
    moves: moves.data,
  }

  return <SetMovesSkillAreaLoaded appState={appState} />;
};
