import { useSnackbar } from "notistack";
import { useState } from "react";
import { decodeToken, isExpired } from "react-jwt";
import { useNavigate, useLocation } from "react-router-dom";

export type LoginDetailsType = {
  username: string;
  password: string;
};

type LoginResponseBodyType = {
  accessToken: string;
};

type RefreshResponseBodyType = {
  accessToken: string;
};

type StateType = {
  isLoggedIn: boolean;
  token: null | string;
  username: string;
};

export type UseAuthenticationReturnType = {
  reloadAuth: () => void; // function for use to refresh authentication with api calls in other react components
  login: (loginDetails: LoginDetailsType) => Promise<void>; // login function
  logout: () => Promise<void>; // logout function
  getToken: () => Promise<string | null>; // function that returns the access token
  isLoggedIn: boolean;
  username: string;
};

type PayloadType = { username: string };

type RefreshType =
  | { message: "success"; token: string }
  | { message: "failure"; token: null };

const getPayloadFromToken = (token: string) => {
  return decodeToken(token) as PayloadType;
};

// hook used for authentication
// check access token expiry whenever a route change occurs
// if access token expired or does not exist, it queries the
// refresh api in case a refresh token exists as httpOnly cookie.

export const useAuthentication = (): UseAuthenticationReturnType => {
  const initialState = {
    initialLoadOccurred: false,
    token: null,
    username: "",
    isLoggedIn: true,
  };

  const [state, setState] = useState<StateType>(initialState);
  const [reload, setReload] = useState<number>(0);

  // used to redirect app after login/logout
  const navigate = useNavigate();
  // display notification toasts
  const { enqueueSnackbar } = useSnackbar();
  // authenticate on location change
  const location = useLocation();

  // call refresh api
  const refreshAuth = async (): Promise<RefreshType> => {
    try {
      const response = await fetch("api/auth/refresh", {
        method: "GET",
        credentials: "same-origin",
      });

      if (response.status !== 200) {
        throw new Error(response.statusText);
      }

      const jsonResponse = (await response.json()) as RefreshResponseBodyType;

      return { message: "success", token: jsonResponse.accessToken };
    } catch (error) {
      return { message: "failure", token: null };
    }
  };

  const reloadAuth = () => {
    setReload(reload + 1);
  };

  const login = async (loginDetails: LoginDetailsType) => {
    try {
      const response = await fetch("api/auth/login", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        credentials: "same-origin",
        body: JSON.stringify(loginDetails),
      });
      if (response.status === 200) {
        const jsonData = (await response.json()) as LoginResponseBodyType;
        const accessToken = jsonData.accessToken;
        const user = getPayloadFromToken(accessToken)!.username;

        setState((previousState) => ({
          ...previousState,
          token: accessToken,
          username: user,
          isLoggedIn: true,
        }));

        enqueueSnackbar("Successfully logged in!", {
          variant: "success",
        });
      } else {
        throw new Error(response.statusText);
      }
    } catch (error) {
      enqueueSnackbar("Unsuccessful Login Attempt!", {
        variant: "error",
      });
    }
  };

  const logoutFunction = async () => {
    try {
      const response = await fetch("api/auth/logout", {
        method: "GET",
        credentials: "include",
        headers: {
          Authorization: "Bearer " + state.token,
        },
      });

      if (response.status === 200) {
        enqueueSnackbar("Successfully logged out!", {
          variant: "success",
        });
        setState((previousState) => ({
          ...previousState,
          token: null,
          username: "",
          isLoggedIn: false,
        }));

        setTimeout(() => {
          navigate("/");
        }, 2000);
      } else {
        throw response.statusText;
      }
    } catch (error) {
      enqueueSnackbar("There was an error when trying to log out!", {
        variant: "error",
      });
      console.error("Error:", error);
    }
  };

  const getToken = async (): Promise<string | null> => {
    let token: string | null = state.token;

    if ((state.token && isExpired(state.token)) || state.token === null) {
      const result = await refreshAuth();
      if (result.message === "success") {
        const username = getPayloadFromToken(result.token).username;
        token = result.token;
        setState((previousState) => ({
          ...previousState,
          token,
          username: username,
          isLoggedIn: true,
        }));
      } else {
        setState((previousState) => ({
          ...previousState,
          token: null,
          username: "",
          isLoggedIn: false,
        }));

        if (location.pathname.startsWith("/shared")) {
          // do nothing, it's a shared page does not require authentication
        } else if (location.pathname !== "/") {
          // if user tried to go directly to a route other than "/"
          // redirect to /Login
          enqueueSnackbar("You are not logged in, please login", {
            variant: "error",
          });
          navigate("/login");
        }
      }
    }
    return token;
  };


  return {
    reloadAuth: reloadAuth,
    login: login,
    logout: logoutFunction,
    getToken: getToken,
    username: state.username,
    isLoggedIn: state.isLoggedIn,
  };
};
