import React from "react";
import { useParams } from "react-router-dom";
import Loading from "components/Loading";
import ErrorComponent from "components/ErrorComponent";
import { Container, Paper, Typography } from "@mui/material";
import { SharedDataCard } from "pages/HomeView/TrainingSummary/DataCard";
import {
  SharedBeltTableTable,
} from "pages/HomeView/TrainingSummary/BeltTable";
import useUserSharedInfo from "data/queries/useUserSharedInfo";

type SharedSummaryViewProps = {};
export const SharedSummaryView: React.VFC<SharedSummaryViewProps> = () => {
  let { username } = useParams();
  const sharedInfo = useUserSharedInfo(username);
  if (!username) {
    return <div>No user specified</div>;
  }

  if (sharedInfo.isLoading || sharedInfo.isIdle) {
    return (
      <Container maxWidth="md">
        <Paper elevation={3} className="paper">
          <Loading />
        </Paper>
      </Container>
    );
  }
  if (sharedInfo.isError) {
    return (
      <Container maxWidth="md">
        <Paper elevation={3} className="paper">
          <ErrorComponent />
        </Paper>
      </Container>
    );
  }

  return (
    <Container maxWidth="md">
      <Paper elevation={3} className="paper">
        <Typography
          variant="h4"
          style={{ textAlign: "center" }}
        >{`${sharedInfo.data.username}'s Training`}</Typography>
        <SharedDataCard data={sharedInfo.data} />
        <SharedBeltTableTable data={sharedInfo.data.beltSummary} />
      </Paper>
    </Container>
  );
};
