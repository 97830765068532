import { AuthContext } from "App";
import axios, { AxiosRequestConfig, AxiosError, AxiosResponse } from "axios";
import { useSnackbar } from "notistack";
import { useContext } from "react";
import { useMutation, UseMutationOptions } from "react-query";

// useInsertData definition wraps react-query useMutation hook
// calls api endpoint to insert data
const useInsertData = <P>(
  apiUrl: string,
  config: {
    method: "POST" | "PUT" | "PATCH";
    snackSuccessMessage?: string;
    snackErrorMessage?: string;
    onSuccessCallback?: UseMutationOptions<
      AxiosResponse<P>,
      AxiosError<P>,
      P
    >["onSuccess"];
    onErrorCallBack?: UseMutationOptions<
      AxiosResponse<P>,
      AxiosError<P>,
      P
    >["onError"];
  } = {
    method: "POST",
  }
) => {
  const { enqueueSnackbar } = useSnackbar();
  const authenticationState = useContext(AuthContext);

  const apiCall = async (sanitisedFormData: P) => {
    const token = await authenticationState.getToken();
    const axiosConfig: AxiosRequestConfig = {
      url: apiUrl,
      method: config.method,
      headers: {
        Authorization: `Bearer ${token}`,
      },
      data: sanitisedFormData,
    };
    return await axios(axiosConfig);
  };

  const mutate = useMutation<AxiosResponse<P>, AxiosError<P>, P>(apiCall, {
    onSuccess: (response, variables, context) => {
      const successMessage =
        config.snackSuccessMessage || "Data successfully logged!";
      enqueueSnackbar(successMessage, {
        variant: "success",
      });
      if (config.onSuccessCallback) {
        config.onSuccessCallback(response, variables, context);
      }
    },
    onError: (error, variables, context) => {
      const errorMessage =
        config.snackErrorMessage || `Error: ${error.message}`;
      enqueueSnackbar(errorMessage, {
        variant: "error",
      });
      if (config.onErrorCallBack) {
        config.onErrorCallBack(error, variables, context);
      }
    },
  });

  return {
    apiCall: mutate.mutate,
    isError: mutate.isError,
    isLoading: mutate.isLoading,
    isSuccess: mutate.isSuccess,
    isPaused: mutate.isPaused,
    isIdle: mutate.isIdle,
    error: mutate.error,
    data: mutate.data,
    status: mutate.status,
  };
};

export default useInsertData;
